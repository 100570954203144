import { Component, OnInit } from '@angular/core';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';

import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Reset } from 'src/app/models/charge-point-actions/reset';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ValueDisplay } from 'src/app/models/value-display';


@Component({
  selector: 'app-raise-security-profile',
  templateUrl: './raise-security-profile.component.html',
  styleUrls: ['./raise-security-profile.component.scss']
})
export class RaiseSecurityProfileComponent implements OnInit {

  constructor(private actionService: ChargingActionsService,private dialogRef:DynamicDialogRef) { }

  raiseSecProfileForm: UntypedFormGroup;
  raiseSecProfileModel: Reset;
  public secProfileTypes: ValueDisplay[] = [
    { display: 'Soft', value: 1 },
    { display: 'Hard', value: 2 }
  ];
  public defaultType: ValueDisplay = { display: 'Type', value: null };

  ngOnInit(): void {
    this.raiseSecProfileForm = new UntypedFormGroup({
      secProfileType: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.raiseSecProfileModel = this.raiseSecProfileForm.value;
  }

  onReset() {
    this.raiseSecProfileForm.reset();
  }

}
