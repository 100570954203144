import { EVSEResponseModel } from '../evse/evse-response';
import { HardwareModel } from '../hardware';
import { ChargingZoneResponse } from '../charging-zone/charging-zone-response';
import { ReservationTime } from './reservation-time';
import { ChargePointImageResponseModel} from '../charge-point-image/charge-point-image-response-model';

export class ChargePointResponseModel {
    emspStatus: boolean;
    chargePointId: number;
    chargePointType: string;
    chargePointTypeDisplay: string;
    name: string;
    chargePointTemplateId: number;
    chargingZoneId: number;
    manufactureDate: number;
    integrationDate: number;
    networkType: string;
    networkTypeDisplay: string;
    systemStatus: string;
    systemStatusDisplay: string;
    connectionStatus: string;
    connectionStatusDisplay: string;
    hardwareStatus: string;
    hardwareStatusDisplay: string;
    updatedDate: number;
    isManagedByOperator: boolean;
    networkProtocol: string;
    networkProtocolDisplay: string;
    chargeBox: string;
    networkPassword: string;
    isEncrypted: boolean;
    isUsesAuthentication: boolean;
    isWithoutAuthorization: boolean;
    enableLocalAuthList: boolean;
    enableAutoFaultRecovery: boolean;
    enableLoadManagement: boolean;
    maxCurrent: string;
    phases: string;
    phasesDisplay: string;
    phaseRotation: string;
    phaseRotationDisplay: string;
    partnerId: number;
    partnerName: string;
    contractId: number;
    contractName: string;
    chargingZone: ChargingZoneResponse;
    chargePointEvse: EVSEResponseModel[];
    chargePointHardware: HardwareModel;
    chargePointReservationTime: ReservationTime[];
    chargePointImages:ChargePointImageResponseModel[];
    locationName: string;
    amenities;
    lastHeartbeatAt: number;
    heartbeatInterval: number;
    chargePointAvailablityCheckCount: number;
    chargePointHarwareInfo;

    msfId: number;
    sfId: number;
    distributorId: number;
    dealerId: number;
    landOwnerAgencyId: number;
    landOwnerId: number;
    msfPercentage: number;
    sfPercentage: number;
    distributorPercentage: number;
    dealerPercentage: number;
    isPublic: boolean;
    type: string;
    loaShare: number;
    loShare: number;
    chargePointOwner: string;
    chargingZoneName: string;
    emspMappings;


    wifiMacAddress: string;
    ethernetMacAddress: string;
    gsmImeiNumber: string;
    lcdSeriesNumber: string;
    controllerSeriesNumber: string;

    simCardDetails: string;
    assetDcoDetails: string;
    otherGeneralisedDetails: string;
    distributionBoxDetails: string;
    
    carbonCreditAgencyId:number;
    compactChargerRequestId:number;
    compactChargerRequestUserId:number;
    compactChargerRequestUserName:string;
    compactChargerRequestEmail:string;
    compactChargerRequestPhone:string;

    passKey:string;
    productCode:string;
    compactChargerUserId:string;

}
