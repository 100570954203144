import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';
import Swal from 'sweetalert2';
import { DataTransferCP } from '../../../models/charge-point-actions/data-transfer';
@Component({
  selector: 'app-data-transfer',
  templateUrl: './data-transfer.component.html',
  styleUrls: ['./data-transfer.component.scss']
})
export class DataTransferComponent implements OnInit {

  constructor(
    private dialogRef: DynamicDialogRef,
    private actionService: ChargingActionsService,
  ) { }

  submitted = false;
  dataTransferForm: UntypedFormGroup;
  dataTransferModel: DataTransferCP = new DataTransferCP();
  ngOnInit(): void {
    this.dataTransferForm = new UntypedFormGroup({
      vendorId: new UntypedFormControl('', Validators.required),
      messageId: new UntypedFormControl(),
      data: new UntypedFormControl(),
      reason: new UntypedFormControl('', Validators.required),
    });
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.dataTransferForm.valid) {
      this.dataTransferModel = this.dataTransferForm.value;
      this.actionService.cpDataTransfer(this.dataTransferModel).subscribe(res => {
        if (res.returnStatus) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            text: res.message,
            showConfirmButton: false,
            timer: 4000
          });
          this.dialogRef.close();
        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            text: res.message,
            showConfirmButton: true,
            confirmButtonText: 'OK'
          });
        }
        this.dialogRef.close();
        this.submitted = false;
      },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        });
    }
    else {
      return;
    }
  }
  onReset(): void {
    this.dataTransferForm.reset();
    this.dialogRef.close();
  }
}
