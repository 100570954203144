import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoaderService } from '../services/loader.service';
import { Common } from '../common/common';
import { ExchangeTokenRequest } from '../models/auth/exchange-token-request';
import { switchMap } from 'rxjs/operators';
import { ErrorCode, RouteUrl, LocalStorageKeys, ErrorMessage} from '../common/constant';
import { Location } from '@angular/common';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        public loaderService: LoaderService,
        public location: Location,
        private toastService: ToastService
    ) { }
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.loaderService.show();
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.loaderService.hide();
                }
            }),
            catchError((error) => {
                this.loaderService.hide();
                if (error.status === ErrorCode.badRequest) {
                    if (error.error && error.error.message &&
                        (error.error.message === ErrorMessage.invalidRefreshToken || error.error.message === ErrorMessage.sessionExpired)) {
                        this.authService.logout();
                        this.router.navigateByUrl(RouteUrl.login);
                        return;
                    } else if (error.error && error.url.indexOf('login') > 0 || error.error && error.url.indexOf('forgot-password') > 0 || error.error && error.url.indexOf('reset-password') > 0) {

                        if (error.error.message === 'Invalid access token') {
                            this.authService.logout();
                            this.router.navigateByUrl(RouteUrl.login);
                            return;
                        }

                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: error.error.message,
                            showConfirmButton: true,
                            confirmButtonText: 'OK'
                        })
                    }
                } else if (error.status === ErrorCode.unauthorizedRequest) {

                  if (!this.isRefreshing) {
                    this.isRefreshing = true;
                    this.refreshTokenSubject.next(null);
                    if (Common.loginResponseModel) {
                        if (typeof (Common.loginResponseModel.emailAddress) !== typeof (0)) {
                            const exchangeTokenRequest: ExchangeTokenRequest = new ExchangeTokenRequest();
                            exchangeTokenRequest.accessToken = Common.loginResponseModel.accessToken;
                            exchangeTokenRequest.refreshToken = Common.loginResponseModel.refreshToken;
                            exchangeTokenRequest.userId = Common.loginResponseModel.emailAddress;
                            return this.authService.exchangeToken(exchangeTokenRequest).pipe(switchMap(response => {
                              this.isRefreshing = false;
                                if (response) {
                                    Common.loginResponseModel.accessToken = response.data.accessToken;
                                    Common.loginResponseModel.refreshToken = response.data.refreshToken;
                                    Common.loginResponseModel.tokenType = response.data.tokenType;
                                    Common.loginResponseModel.accessTokenExpiryEpochTime =
                                    Common.getFormatedDate(response.data.accessTokenExpiryEpochTime);
                                    localStorage.setItem(LocalStorageKeys.loginResponse, JSON.stringify(Common.loginResponseModel));
                                    this.refreshTokenSubject.next(response.data.accessToken);
                                    const newRequest = request.clone({
                                        headers: request.headers.set('Authorization',
                                            'Bearer ' + Common.loginResponseModel.accessToken)
                                    });
                                    return next.handle(newRequest);
                                }
                                else {
                                    this.authService.logout();
                                    this.router.navigateByUrl(RouteUrl.login);
                                    return;
                                }
                            }
                            ));
                        } else {
                            this.authService.logout();
                            this.router.navigateByUrl(RouteUrl.login);
                            return;
                        }
                    } else {
                        this.authService.logout();
                        this.router.navigateByUrl(RouteUrl.login);
                        return;
                    }
                  }else {
                    // Wait for the token refresh to complete
                   return this.refreshTokenSubject.pipe(
                      filter(token => token != null),
                      take(1),
                      switchMap(() => next.handle(request.clone({
                        headers: request.headers.set('Authorization',
                            'Bearer ' + this.refreshTokenSubject.value)
                        })))
                    );
                  }
                } else if (error.status === ErrorCode.accessDenied) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Access denied !'
                        });
                        console.log('Access denied for url:' + error.url);

                } else if (error.status === ErrorCode.internalServer) {
                    Swal.fire({
                        icon: 'error',
                        text: error?.statusText?error?.statusText:error?.error?.message,
                        showConfirmButton: true,
                        confirmButtonText: 'OK'
                    });
                } else if (error.status === ErrorCode.sessionExpired) {
                    this.authService.logout();
                    this.router.navigateByUrl(RouteUrl.login);
                }
                else if (error.status === ErrorCode.notfound) {
                    Swal.fire({
                        icon: 'warning',
                        text: error?.error?.message ? error?.error?.message : error?.statusText,
                        showConfirmButton: true,
                        confirmButtonText: 'OK'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: error?.error?.message ? error?.error?.message : error?.statusText,
                        showConfirmButton: true,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (error.status === ErrorCode.accessDenied) {
                                this.location.back();
                            }
                        }
                    });
                }
                return throwError(error);
            })
        );
    }
}
