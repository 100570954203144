import { Component, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

import { ChargingActionsService } from 'src/app/services/charging-actions.service'

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateFirmware } from 'src/app/models/charge-point-actions/update-firmware';
import Swal from 'sweetalert2';
import { Common } from '../../common';
import { FirmwareDetailsService } from 'src/app/services/firmware-details.service';
import { FirmwareDetailsResponse } from 'src/app/models/firmware-details/firmware-details-response';
import { ToastService } from 'src/app/services/toast.service';
import { ValueDisplay } from 'src/app/models/value-display';
import { BehaviorSubject } from 'rxjs';
import { ChargePonitService } from 'src/app/services/charge-point.service';
import { ValueDisplayString } from 'src/app/models/value-display-string';
import { chargePointType, ChargePointTypeEnum, firmwareACTypeData,firmwareDCTypeData } from '../../constant';
import { ChargePointTypeVM } from 'src/app/models/charge-point/charge-point-type-vm';
import { number } from 'echarts';

@Component({
  selector: 'app-update-firmware',
  templateUrl: './update-firmware.component.html',
  styleUrls: ['./update-firmware.component.scss']
})
export class UpdateFirmwareComponent implements OnInit {

  public currentDate: Date = new Date(new Date().getDate());
  submitted = false;
  public gridData: FirmwareDetailsResponse[] = [];
  displayVersionData: ValueDisplay[] = [];
  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef, private firmwareDetailsService: FirmwareDetailsService, private toasterService: ToastService, private chargePointService: ChargePonitService) { }
  public behaviorSubject = new BehaviorSubject<string>(null);
  updateFirmawareModel: UpdateFirmware;
  updateFirmawareForm: UntypedFormGroup;
  public chargePointType: string;
  public chargePointTypeValue: string;
  public firmwareDetailsId: number;
  public isBulkUpdate: boolean;
  selectedChargePointIds: number[] =[]
  chargePointTypeEnum = ChargePointTypeEnum
  chargePointTypeData: ValueDisplayString[] = chargePointType;
  chargePointACTypeData: ValueDisplayString[] = firmwareACTypeData;
  chargePointDCTypeData: ValueDisplayString[] = firmwareDCTypeData;
  chargePointData:  ChargePointTypeVM[] = [];
  minDate: Date | undefined;
  get chargePoint(): AbstractControl { return this.updateFirmawareForm.get('chargePoint'); }
  get firmwareType(): AbstractControl { return this.updateFirmawareForm.get('firmwareType'); }
  get firmwareDetailId(): AbstractControl { return this.updateFirmawareForm.get('firmwareDetailId'); }
  get retries(): AbstractControl { return this.updateFirmawareForm.get('retries'); }
  get retryInterval(): AbstractControl { return this.updateFirmawareForm.get('retryInterval'); }
  get retrieveAfter(): AbstractControl { return this.updateFirmawareForm.get('retrieveAfter'); }
  get reason(): AbstractControl { return this.updateFirmawareForm.get('reason'); }
  ngOnInit(): void {
    this.isBulkUpdate = true;
    this.chargePointType = this.chargePointService.getChargePointType();
    this.firmwareDetailsId = this.firmwareDetailsService.getFirmwareDetailId();
    if(this.chargePointType != undefined || this.chargePointType != null){
      this.isBulkUpdate = false;
      const chargePointSelected = this.chargePointTypeData.filter(a=>a.value == this.chargePointType);
      this.chargePointType = chargePointSelected[0].display;
      this.chargePointTypeValue = chargePointSelected[0].value;
    }
    
    this.updateFirmawareForm = new UntypedFormGroup({
      firmwareDetailId: new UntypedFormControl(number),
      chargePoint: new UntypedFormControl(null),
      firmwareType: new UntypedFormControl(null),
      retries: new UntypedFormControl(null,Validators.required),
      retryInterval: new UntypedFormControl(null,Validators.required),
      retrieveAfter: new UntypedFormControl(null,Validators.required), // Epoch date time
      reason: new UntypedFormControl('',Validators.required),
    });
    if (this.isBulkUpdate) {
      this.updateFirmawareForm.get('chargePoint')?.setValidators(Validators.required);
    }else{
      this.updateFirmawareForm.get('firmwareType')?.setValidators(Validators.required);
      this.updateFirmawareForm.get('firmwareDetailId')?.setValidators(Validators.required);
    }
    this.minDate = new Date();
    this.getFirmwareList();
  }
  getFirmwareList(): void {
    this.firmwareDetailsService.getFirmwareList().subscribe(response => {
      if (response.returnStatus) {
        this.gridData = response.data;
        response.data.forEach(element => {
          if(this.chargePointType != undefined || this.chargePointType != null){
            if(this.chargePointType == element.chargePointType){
              const value = new ValueDisplay;
              value.value= element.firmwareDetailId;
              value.display= element.firmwareVersion;
              this.displayVersionData.push(value);
            }
          }
          if(this.firmwareDetailsId != undefined || this.firmwareDetailsId != null){
            if(element.firmwareDetailId == this.firmwareDetailsId){
              this.getChargePointListByType(element.chargePointType);
              this.isBulkUpdate = true;
              const value = new ValueDisplay;
              value.value= element.firmwareDetailId;
              value.display= element.firmwareVersion;
              this.displayVersionData.push(value);
              this.chargePointType = element.chargePointType;
            }
          }
        });
       
    } else {
      this.toasterService.showError('Problem in Fetching Image Detail.');
    }
    });
  }
  getChargePointListByType(chargePointType: string)
  {
    this.chargePointService.getChargePointsByType(chargePointType).subscribe(response => {
      if (response.returnStatus) {
        this.chargePointData = response.data;
      } else {
        this.toasterService.showError('Problem in Fetching Image Detail.');
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    this.updateFirmawareModel = this.updateFirmawareForm.value;
    if(this.selectedChargePointIds.length > 0){
      this.updateFirmawareModel.chargePointIdList = this.selectedChargePointIds;
      this.updateFirmawareModel.firmwareDetailId = this.firmwareDetailsId;
    }else{
      this.updateFirmawareModel.chargePointIdList = [];
    }
    this.updateFirmawareModel.retrieveAfter =  Common.getEPOCH(new Date(this.updateFirmawareModel.retrieveAfter))
      this.actionService.updateFirmware(this.updateFirmawareModel).subscribe(res => {
      if (res.returnStatus) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Success',
          text: res.message,
          showConfirmButton: false,
          timer: 4000
        });
        this.dialogRef.close();
      }
      else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error',
          text: res.message,
          showConfirmButton: true,
          confirmButtonText: 'OK'
        });
      }
      this.dialogRef.close();
      this.submitted = false;
      },
      (error) => {
        console.log(error);
        this.dialogRef.close();
      });
  }

  onReset() {
    this.updateFirmawareForm.reset();
  }
  changeFirmwareType(event)
  {
    this.displayVersionData = [];
    this.gridData.forEach(element => {
      if(event.value == element.firmwareType && element.chargePointType == this.chargePointTypeValue){
        const value = new ValueDisplay;
        value.value= element.firmwareDetailId;
        value.display= element.firmwareVersion;
        this.displayVersionData.push(value);
      }
      
    });
  }
  selectChargePoint(event)
  {
    this.selectedChargePointIds = event.value;
  }

}
