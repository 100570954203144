<div class="row">
    <div class="col-12">
        <div class="text-center">
            <div *ngIf="clientLogo.logoFilePath" class="div-public-evse-logo">
                <img alt=""src="{{clientLogo?.logoFilePath}}" onerror="this.src='../../../assets/images/EVHES_LOGO.png'">
            </div>
            <div *ngIf="!clientLogo?.logoFilePath" class="div-public-evse-logo">
                <img alt="" src="../../../assets/images/EVHES_LOGO.png">
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-xl-12 text-center">
        <h1>EVSE QR Code: {{qrCodeId}}</h1>
    </div>
</div>