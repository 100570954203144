export class AddPartnerVM{    
    name: string;
    email: string;
    type: string;
    typeDisplay: string;
    registrationNo: string;
    vatId: string;
    country: string;
    city: string;
    postcode: string;
    address: string;
    contactPerson: string;
    phone: string;
    activateCorporateBilling: true;
    corporateBillingLimit: number;
    discount: number;
    monthlyPlatformFee: number;
}