import { Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChargingProfileService } from 'src/app/services/charging-profile.service';
import Swal from 'sweetalert2';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/services/common.service';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';
import { SetChargingProfileModel } from 'src/app/models/charging-profile/set-charging-profile-model';

@Component({
  selector: 'app-set-charging-profile',
  templateUrl: './set-charging-profile-charger.component.html',
  styleUrls: ['./set-charging-profile-charger.component.scss']
})
export class SetChargingProfileChargerComponent implements OnInit {
  source;
  isConnectorLevel: boolean;

  constructor(
    public chargingProfileService: ChargingProfileService,
    private dialogRef: DynamicDialogRef,
    public commonService: CommonService,
    public actionService: ChargingActionsService,
  ) { }
  setChargingProfileForm: FormGroup;
  chargePointId: number;
  chargingProfileId: number;
  isChargePointSelected: boolean;
  submitted = false;
  setChargingProfile: SetChargingProfileModel = new SetChargingProfileModel();
  //defaultConnector: ValueDisplayString =
  public chargingProfileList;


  getChargingProfileList(chargePointId): void {
    this.chargingProfileService.getChargingPofileList(chargePointId).subscribe(response => {
      if (response.returnStatus) {
        this.chargingProfileList = response.data;
        this.chargingProfileList.forEach(cp => {
          if(!this.actionService.isSetChargingProfile){
            if(cp.isProfileSet){
              cp.isProfileSet = false;
            }else{
              cp.isProfileSet = true;
            }
          }

        });
      }
    },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      });
  }
  getChargingProfileListEVSE(evseId): void {
    this.chargingProfileService.getChargingPofileListEVSE(evseId).subscribe(response => {
      if (response.returnStatus) {
        this.chargingProfileList = response.data;
        this.chargingProfileList.forEach(cp => {
          if(!this.actionService.isSetChargingProfile){
            if(cp.isProfileSet){
              cp.isProfileSet = false;
            }else{
              cp.isProfileSet = true;
            }
          }

        });
      }
    },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      });
  }

  ngOnInit(): void {
    this.chargePointId = this.actionService.chargePointIdforAction;
    if (this.actionService.setClearConnectorLevel) {
      this.getChargingProfileListEVSE( this.actionService.chargePointEVSEIdforAction);
    } else {
      this.getChargingProfileList(this.chargePointId);
    }

    this.setChargingProfileForm = new FormGroup({
          chargingProfile: new FormControl(null,Validators.required),
        });
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.setChargingProfileForm.valid) {
      this.setChargingProfile.chargePointId =  [this.chargePointId];
      this.setChargingProfile.connectorId = 0;
      if (this.actionService.setClearConnectorLevel) {
        this.setChargingProfile.connectorId = parseInt(this.actionService.connectorIdForAction);
      } else {
        this.setChargingProfile.connectorId = 0;
      }

      if (this.actionService.isSetChargingProfile) {
        this.chargingProfileService.setChargingProfile( this.setChargingProfileForm.controls.chargingProfile.value, this.setChargingProfile).subscribe(res => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
            this.dialogRef.close();
          }
          else {
            let errMessage = res.message;
            if (res.errors !== null) {
              errMessage = res.errors[0].message;
            }
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: errMessage,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
          (error) => {
            console.log(error);
            this.dialogRef.close();
          });
      }
      else {
        this.chargingProfileService.clearChargingProfile(this.setChargingProfileForm.controls.chargingProfile.value, this.setChargingProfile).subscribe(res => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
            this.dialogRef.close();
          }
          else {
            let errMessage = res.message;
            if (res.errors !== null) {
              errMessage = res.errors[0].message;
            }
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: errMessage,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
          (error) => {
            console.log(error);
            this.dialogRef.close();
          });
      }

    }
    else {
      return;
    }
  }
  onReset(): void {
    this.setChargingProfileForm.reset();
    this.dialogRef.close();
  }
}
