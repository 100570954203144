<div class="row">
  <div class="col-xl-12">
    <form [formGroup]="setChargingProfileForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
      <table class="table mb-0 table-bordered table-borderless create-evse">
        <tbody class="border-none">
          <tr>
            <td width="200">Charging Profile<span class="text-danger text-sm ml-2"><sup>*</sup></span></td>
            <td>
              <p-dropdown appendTo="body" lazy="true" #chargePointDP [options]="chargingProfileList"
                            placeholder="Charging Profile"  optionLabel="chargingProfileName" optionValue="chargingProfileId" [filter]="true"
                            formControlName="chargingProfile" optionDisabled="isProfileSet" >
                            </p-dropdown>

              <br />
              <span class="text-danger pt-2 float-left"
                *ngIf="(setChargingProfileForm.controls.chargingProfile.touched || submitted) && setChargingProfileForm.controls.chargingProfile.errors?.required">
                Charging profile is required
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="mt-3 text-center">
              <div class="d-inline">
                <input *ngIf="actionService.isSetChargingProfile" type="submit" class="btn btn-hes mr-3" value="Set Charging Profile">
                <input *ngIf="!actionService.isSetChargingProfile" type="submit" class="btn btn-hes mr-3" value="Clear Charging Profile">
                <input type="reset" class="btn btn-danger" value="Cancel">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
