//import { DialogRef } from '@progress/kendo-angular-dialog';
import Swal from 'sweetalert2';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-cancel-reservation',
  templateUrl: './cancel-reservation.component.html',
  styleUrls: ['./cancel-reservation.component.scss']
})
export class CancelReservationComponent implements OnInit {

  submitted = false;
  stopSessionModel;
  stopSessionForm: UntypedFormGroup;
  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
    this.stopSessionForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.stopSessionForm.valid) {
      this.stopSessionModel = this.stopSessionForm.value;
      this.actionService.cancelReservation(this.stopSessionModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: res.message,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      return;
    }
  }
  onReset(): void {
    this.stopSessionForm.reset();
    this.dialogRef.close();
  }
}
