import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common/api-urls';
import { FirmwareDetailsResponse } from '../models/firmware-details/firmware-details-response';
import { FirmwareDetails } from '../models/firmware-details/firmware-details';
import { UpdateFirmwareHistoryListVM } from '../models/firmware-update-history/update-firmware-history-list';
import { ResponseModel } from '../models/response-model';
import { ResponseModelListForPaging } from '../models/response-model-list';

@Injectable({
  providedIn: 'root'
})
export class FirmwareDetailsService {

  constructor(private http: HttpClient) { }
  firmwareDetailId: number;
  getFirmwareList(): Observable<ResponseModelListForPaging<FirmwareDetailsResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelListForPaging<FirmwareDetailsResponse>>(ApiUrls.firmwareDetailsList, options);
  }

  addFirmwareDetails(firmwareDetails: FormData): Observable<ResponseModel<FirmwareDetails>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.post<ResponseModel<FirmwareDetails>>(ApiUrls.addfirmwareDetails, firmwareDetails, options);
  }
  updateFirmwareDetails(firmwareDetails: FormData): Observable<ResponseModel<FirmwareDetails>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.put<ResponseModel<FirmwareDetails>>(ApiUrls.addfirmwareDetails, firmwareDetails, options);
  }
  deleteFirmwareDetails(firmwareDetails :FirmwareDetails ): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() , body: firmwareDetails };
    return this.http.delete<ResponseModel<string>>(ApiUrls.firmwareDetailsDelete, options);
  }
  getFirmwareUpdateHistoryData(firmwareDetailId,gridStateData): Observable<ResponseModelListForPaging<UpdateFirmwareHistoryListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<UpdateFirmwareHistoryListVM>>(ApiUrls.firmwareUpdateHistoryByfirmwareDetails.replace('firmwareDetailId', firmwareDetailId),gridStateData, options);
  }
  setFirmwareDetailId(data)
  {
    this.firmwareDetailId = data;
  }
  getFirmwareDetailId(){
      return this.firmwareDetailId;
  }
}