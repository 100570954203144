import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/response-model';
import { Reset } from '../models/charge-point-actions/reset';
import { Diagnostic } from '../models/charge-point-actions/diagnostic';
import { UpdateFirmware } from '../models/charge-point-actions/update-firmware';
import { TriggerMessage } from '../models/charge-point-actions/trigger-message';
import { Reason } from '../models/charge-point-actions/reason-for-manual-start';
import { ValueDisplayString } from '../models/value-display-string';
import { ResponseModelList } from '../models/response-model-list';
import { StartSession } from '../models/charge-point-actions/start-session';
import { StopSession } from '../models/charge-point-actions/stop-session';
import { Observable } from 'rxjs';
import { ReservationTariff } from '../models/reservation-tariff/reservation-tariff';
import { ReservationTime } from '../models/charge-point/reservation-time';
import {CheckReservationModel} from '../models/reservation-tariff/check-reservation-model'
import { DataTransferCP } from '../models/charge-point-actions/data-transfer';
import {DiagnosticDetailsModel} from '../models/diagnostic-details/diagnostic-details';
@Injectable({
  providedIn: 'root'
})
export class ChargingActionsService {

  constructor(private http: HttpClient) { }

  chargePointIdforAction: number;
  chargePointEVSEIdforAction: number;
  chargePointSessionId: number;
  chargePointReservationId: number;
  reservationTariff: ReservationTariff;
  chargePointReservationTime: ReservationTime[];
  isSetChargingProfile : boolean;
  connectorIdForAction : string;
  setClearConnectorLevel : boolean;
  reset(model: Reset): Observable<ResponseModel<string>> {
    model.chargePointId = this.chargePointIdforAction;
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActionreset + 'reset', model, options);
  }
  diagnostics(model: Diagnostic): Observable<ResponseModel<string>> {
    if(model.chargePointId == null){
      model.chargePointId = this.chargePointIdforAction;
    }
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActiondiagnostics + 'diagnostics', model, options);
  }
  triggerMessage(model: TriggerMessage): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    if (this.chargePointIdforAction) {
      model.chargePointId = this.chargePointIdforAction;
      return this.http.post<ResponseModel<string>>(ApiUrls.cpAction + 'trigger-message', model, options);
    }
    else if (this.chargePointEVSEIdforAction) {
      model.chargePointEVSEId = this.chargePointEVSEIdforAction;
      return this.http.post<ResponseModel<string>>(ApiUrls.evseTriggermessage, model, options);
    }
  }

  getMessageTypes(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.cpAction + 'trigger-message/type', options);
  }

  getResetTypes(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.cpAction + 'reset/type', options);
  }

  updateFirmware(model: UpdateFirmware): Observable<ResponseModel<string>> {
    if(model.chargePointIdList.length <= 0){
      model.chargePointIdList.push(this.chargePointIdforAction);
    }
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActionupdatefirmware + 'update-firmware', model, options);
  }
  compositeSchedule(model: Reason): Observable<ResponseModel<string>> {
    model.chargePointId = this.chargePointIdforAction;
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActioncompositeschedule + 'composite-schedule', model, options);
  }
  clearCache(model: Reason): Observable<ResponseModel<string>> {
    model.chargePointId = this.chargePointIdforAction;
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActionclearcache + 'clear-cache', model, options);
  }

  startSession(model: StartSession): Observable<ResponseModel<string>> {
    model.chargePointEVSEId = this.chargePointEVSEIdforAction;
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.evseStartSession, model, options);
  }

  checkReservation(userId): Observable<ResponseModel<CheckReservationModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<CheckReservationModel>>(ApiUrls.checkReservation.replace("{evseId}",this.chargePointEVSEIdforAction.toString()).replace("{excludeUseId}",userId), options);
  }

  stopSession(model: StopSession): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.evseStopSession + this.chargePointSessionId, model, options);
  }

  cancelReservation(model): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.cancelReservation.replace("{chargePointReservationId}",this.chargePointReservationId.toString()), model, options);
  }

 public changeAvailability(model): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.changeAvailability, model, options);
  }

  cpDataTransfer(model: DataTransferCP): Observable<ResponseModel<string>> {
    model.chargePointId = this.chargePointIdforAction;
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.cpActionDataTransfer, model, options);
  }

  getDiagnosticFiles(chargePointId: number): Observable<ResponseModelList<DiagnosticDetailsModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<DiagnosticDetailsModel>>(ApiUrls.getDiagnosticFiles.replace("{chargePointId}",chargePointId.toString()), options);
  }

  public downloadDiagnostic(diagnosticModel :DiagnosticDetailsModel) {
    return this.getDiagnosticFile(diagnosticModel);
  }

  public getDiagnosticFile(diagnosticModel :DiagnosticDetailsModel):Observable<Blob> {
    const options = { headers: Common.getAuthorizeHeader(),responseType: 'arraybuffer' as 'json'  };
    return this.http.post<Blob>(ApiUrls.downloadDiagnosticFile, diagnosticModel, options);
  }
}
