import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';

import Swal from 'sweetalert2';
import { PayOutstandingRequest } from '../../../models/user/pay-outstanding-request';
import { Common } from '../../common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pay-outstanding',
  templateUrl: './pay-outstanding.component.html',
  styleUrls: ['./pay-outstanding.component.scss']
})
export class PayOutstandingComponent implements OnInit {

  constructor(public userService: UserService,private dialogRef: DynamicDialogRef) { }

  submitted = false;
  payOutstandingForm: UntypedFormGroup;
  payOutstandingModel: PayOutstandingRequest = new PayOutstandingRequest();
  walletAmount: number;
  currencySymbol: string;
  ngOnInit(): void {
    this.walletAmount = this.userService.walletAmount;
    this.currencySymbol = Common.loginResponseModel.currencySymbol;
    this.payOutstandingForm = new UntypedFormGroup({
      useWalletBalance: new UntypedFormControl(false),
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.payOutstandingForm.valid) {
      this.payOutstandingModel = this.payOutstandingForm.value;
      this.userService.payOutstanding(this.payOutstandingModel).subscribe(
        (res) => {
          this.userService.walletAmount = res.data.balanceAmount;
          this.userService.outstandingAmount = res.data.outstandingAmount;
          if (res.returnStatus) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Outstanding amount paid successfully",
            });
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: res.message,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      return;
    }
  }

  onReset(): void {
    this.payOutstandingForm.reset();
    this.dialogRef.close();
  }

}
