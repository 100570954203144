import { Injectable } from '@angular/core';
import { ReservationsVM } from '../models/activity/reservations-vm';
import { ApiUrls } from '../common/api-urls';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { ResponseModel } from '../models/response-model';
import { ReservationRequest} from '../models/reservation-tariff/reservation-request';

import { Observable } from 'rxjs';
import { ReservationListByDate} from '../models/reservation-tariff/reservation-list-by-date';
import { ReservationListVM } from '../models/reservation-list/reservation-list';
import { ValueDisplay } from '../models/value-display';


@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  userId: number;
  reservationId : number;
  selectedReservation : ReservationsVM;

  constructor(private http: HttpClient) {
  }

  getReservationsList(): Observable<ResponseModelList<ReservationListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ReservationListVM>>(ApiUrls.reservation, options);
  }
  getReservationsListPagination(customeState): Observable<ResponseModelListForPaging<ReservationListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ReservationListVM>>(ApiUrls.reservationPagination,customeState, options);
  }
  getReservationsUpComingList(reservationType:string,customeState): Observable<ResponseModelListForPaging<ReservationListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ReservationListVM>>(ApiUrls.reservationUpComingList.replace('{$filter}',reservationType),customeState, options);
  }

  getReservationById(id:number): Observable<ResponseModel<ReservationListVM>>  {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ReservationListVM>>(ApiUrls.reservation + '/' +id, options);
  }

  saveReservation(reservationObj:ReservationRequest)
  {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<ReservationsVM>>(ApiUrls.saveReservation,reservationObj,options);
  }

  saveRfidReservation(reservationObj:ReservationRequest)
  {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<ReservationsVM>>(ApiUrls.saveRfidReservation,reservationObj,options);
  }

  //{chargePointEvseId}/web/reservation/{reservationDateEpoch}`;
  reservationListBydate(epochdate: number, evseid: number): Observable<ResponseModelList<ReservationListByDate>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ReservationListByDate>>((ApiUrls.reservationListByDate.replace("{chargePointEvseId}",evseid.toString())).replace("{reservationDateEpoch}",epochdate.toString()), options);
  }

  getUsers(filterKey,filterValue): Observable<ResponseModelList<ValueDisplay>> {
    const options = { headers: Common.getAuthorizeHeader() };
     return this.http.get<ResponseModelList<ValueDisplay>>(ApiUrls.userFilter.replace('{$filterKey}',filterKey).replace('{$filterValue}',filterValue), options);
  }

}
