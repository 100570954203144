import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { fromEvent } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReservationTime } from '../../../models/charge-point/reservation-time';
import { ReservationListByDate } from '../../../models/reservation-tariff/reservation-list-by-date';
import { ReservationRequest, ReservationRequestTime } from '../../../models/reservation-tariff/reservation-request';
import { ReservationTariff } from '../../../models/reservation-tariff/reservation-tariff';
import { ValueDisplayString } from '../../../models/value-display-string';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { ReservationsService } from '../../../services/reservations.service';
import { RFIDTagsService } from '../../../services/rfid-tags.service';
import { UserService } from '../../../services/user.service';
import { Common } from '../../common';
import { HttpErrorResponse } from '@angular/common/http';
import { ValueDisplay } from 'src/app/models/value-display';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, AfterViewInit {
  reservationOptions = [{ label: 'RFID', value: false }, { label: 'User', value: true }];
  public checked = true;
  public data: ValueDisplayString[] = [];
  public value: Date = new Date();
  public defaultmessageType: ValueDisplayString = { display: 'Type', value: null };
  public messageTypes: ValueDisplayString[] = [];
  public defaultuser: ValueDisplayString = new ValueDisplayString();
  public userList: ValueDisplayString[] = [];
  reservationrequestModel: ReservationRequest = new ReservationRequest();
  reservationTariff: ReservationTariff;
  currentDate = new Date();
  public min: Date = new Date();
  public max: Date = new Date((new Date().setDate(new Date().getDate() + this.actionService.reservationTariff.maxNoOfDays)));
  public reservationListByDate: ReservationListByDate[] = [];
  public rangeValidation = true;
  reservationTime: ReservationTime[] = [];
  isRfid = false;
  reservationForm: UntypedFormGroup;
  taglist: ValueDisplayString[] = [];
  timeslots: Itimeslot[] = [];
  timeslot: Itimeslot;
  selectedSlot: SelectedSlot[] = [];
  submitted = false;
  usersData: ValueDisplay[];
  @ViewChild('mobileUserRfId', { read: ElementRef }) mobileUserRfId: ElementRef;
  source;
  placeholder = 'Select Mobile User';
  constructor(private dialogRef: DynamicDialogRef,
    private userService: UserService,
    private rfidTagsService: RFIDTagsService,
    private reservationService: ReservationsService,
    private actionService: ChargingActionsService) {
    this.data = this.userList.slice();
    this.currentDate.setHours(0, 0, 0, 0);
  }

  

  ngAfterViewInit(): void {
    this.source = fromEvent(this.mobileUserRfId.nativeElement, 'keyup');
    this.source.pipe(debounceTime(1200)).subscribe(c => {
      this.usersChangeFilter(c.target.value);
    });
  }
  ngOnInit(): void {


  //  const h = 0;
    let serialNo = 1;
    const getday = this.currentDate.getDate();

    this.reservationForm = new UntypedFormGroup({
      availType: new UntypedFormControl(true, Validators.required),
      tagId: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
      reservationDate: new UntypedFormControl(this.value, Validators.required)
    });
    let inRange = false;

    this.defaultuser.display = 'Select Mobile User';
    this.defaultuser.value = null;
    //this.getUserList();
    this.timeslot = new Itimeslot();
    this.timeslot.id = serialNo;
    this.timeslot.slot = ("0" + this.currentDate.getHours()).slice(-2) + ":" + ("0" + this.currentDate.getMinutes()).slice(-2);
    const enddate = new Date(this.currentDate.getTime() + ((this.actionService.reservationTariff.minDurationHours * 60) * 60000));
    this.timeslot.displaySlot = this.timeslot.slot + "    " + ("0" + enddate.getHours()).slice(-2) + ":" + ("0" + enddate.getMinutes()).slice(-2);

    //get active chargepoint time slot reservation
    this.actionService.chargePointReservationTime.forEach(element => {
      if (this.currentDate > new Date() && (this.currentDate >= new Date(element.reservationStartTime) && this.currentDate <= new Date(element.reservationEndTime))) {
        inRange = true;
      }
    });
    this.timeslot.isAvailable = inRange;
    this.timeslot.isClicked = false;
    this.timeslot.isOccupied = false;
    this.timeslots.push(this.timeslot);

    //set  current date time
    this.actionService.chargePointReservationTime.every(element => {
      let hour = new Date(element.reservationStartTime).getHours();
      let min = new Date(element.reservationStartTime).getMinutes();
      let dd = new Date();
      dd.setHours(hour, min, 0, 0);
      element.reservationStartTime = dd.getTime();

      hour = new Date(element.reservationEndTime).getHours();
      min = new Date(element.reservationEndTime).getMinutes();
      dd = new Date();
      dd.setHours(hour, min, 0, 0);
      element.reservationEndTime = dd.getTime();
    });
    const incre = (this.actionService.reservationTariff.minDurationHours * 60);
    for (let i = 1; i <= 1440; i = i + incre) {
      inRange = false;
      serialNo++;
      this.currentDate = new Date(this.currentDate.getTime() + (this.actionService.reservationTariff.minDurationHours * 60) * 60000);
      this.actionService.chargePointReservationTime.forEach(element => {
        const reservdate = new Date(this.reservationForm.controls.reservationDate.value);
        reservdate.setHours(new Date(element.reservationStartTime).getHours(), new Date(element.reservationStartTime).getMinutes(), 0, 0);

        const reservEndDate = new Date(this.reservationForm.controls.reservationDate.value);
        reservEndDate.setHours(new Date(element.reservationEndTime).getHours(), new Date(element.reservationEndTime).getMinutes(), 0, 0);

        if (this.currentDate > new Date() && this.currentDate >= reservdate && this.currentDate <= reservEndDate) {
          inRange = true;
        }
      });

      if (this.currentDate.getDate() == getday) {
        this.timeslot = new Itimeslot();
        this.timeslot.id = serialNo;
        this.timeslot.slot = ("0" + this.currentDate.getHours()).slice(-2) + ":" + ("0" + this.currentDate.getMinutes()).slice(-2);

        const enddate = new Date(this.currentDate.getTime() + ((this.actionService.reservationTariff.minDurationHours * 60) * 60000));
        this.timeslot.displaySlot = this.timeslot.slot + "   " + ("0" + enddate.getHours()).slice(-2) + ":" + ("0" + enddate.getMinutes()).slice(-2);

        console.log(this.timeslot.displaySlot);
        this.timeslot.isAvailable = inRange;
        this.timeslot.isClicked = false;
        this.timeslot.isOccupied = false;
        this.timeslots.push(this.timeslot);
      }
    }
    this.onDateChange();
  }

  usersChangeFilter(event): void {
    //if (event.length >= 1) {
    if (this.isRfid) {
      this.getUsersDetail('rfid_tags', event);
    } else {
      this.getUsersDetail('users', event);
    }

    // }
  }

  getUsersDetail(filterKey, filterValue): void {
    this.reservationService.getUsers(filterKey, filterValue).subscribe(response => {
      if (response.returnStatus) {
        this.usersData = response.data;
      }
    },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      });
  }


  onDateChange() {
    this.timeslots.map((todo) => { todo.isOccupied = false; todo.isClicked = false; });
    const selectedDate = new Date(this.reservationForm.controls.reservationDate.value);
    selectedDate.setHours(0, 0, 0, 0);
    this.reservationService.reservationListBydate(Common.getEPOCH(this.reservationForm.controls.reservationDate.value), this.actionService.chargePointEVSEIdforAction).subscribe(response => {
      this.reservationListByDate = response.data;


      this.timeslots.forEach(slot => {
        selectedDate.setHours(+slot.slot.split(":")[0], +slot.slot.split(":")[1], 0, 0);

        let inrange = false;
        this.actionService.chargePointReservationTime.forEach(element => {
          const reservdate = new Date(this.reservationForm.controls.reservationDate.value);
          reservdate.setHours(new Date(element.reservationStartTime).getHours(), new Date(element.reservationStartTime).getMinutes(), 0, 0);

          const reservEndDate = new Date(this.reservationForm.controls.reservationDate.value);
          reservEndDate.setHours(new Date(element.reservationEndTime).getHours(), new Date(element.reservationEndTime).getMinutes(), 0, 0);

          if (selectedDate > new Date() && selectedDate >= reservdate && selectedDate <= reservEndDate) {
            inrange = true;
          }
        });
        slot.isAvailable = inrange;
        this.reservationListByDate.forEach(element => {
          if (selectedDate >= new Date(element.startAt) && selectedDate < new Date(element.endAt)) {
            slot.isOccupied = true;
            slot.userInfo = element.userFirstName + ' ' + element.userLastName;
            slot.chargePointReservationId = element.chargePointReservationId;
          }
        });
      });
    });
  }

  getUserList() {
    this.userService.getUsers().subscribe(response => {
      if (response.returnStatus) {
        response.data.forEach(element => {
          const user = new ValueDisplayString();
          user.display = element.email;
          user.value = element.userId.toString();
          this.userList.push(user);
        });
      }
    });
  }

  getRFIDTag() {
    this.rfidTagsService.getrfidtags().subscribe(
      (res) => {
        if (res.returnStatus) {
          res.data.forEach(element => {
            const user = new ValueDisplayString();
            user.display = element.tagId;
            user.value = element.rfidTagId.toString();
            this.userList.push(user);
          });
        } 
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      }
    );
  }

  onReset(): void {
    this.reservationForm.reset();
    this.dialogRef.close();
  }
  handleFilter(value): void {
    this.data = this.userList.filter((s) => s.display.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onChange(value: string): void {
    this.data = [];
    this.userList = [];
    this.reservationForm.controls.tagId.setValue(null);
    if (value) {
      this.placeholder = 'Select Mobile User';
      this.defaultuser.value = null;
      // this.getUserList();
      this.isRfid = false;
      this.usersData = [];
    }
    else {
      this.isRfid = true;
      this.placeholder = 'Select Tag';
      this.defaultuser.value = null;
      this.usersData = [];
      // this.getRFIDTag();
    }
  }

  onCheckedChange(slot: string, isChecked: boolean) {
    const item = this.timeslots.find(x => x.slot == slot);

    item.isClicked = isChecked;
    if (isChecked) {
      const selected: SelectedSlot = new SelectedSlot();
      selected.id = item.id;
      selected.slot = item.slot;
      this.selectedSlot.push(selected);
    }
    else {
      const selectedItem = this.selectedSlot.find(x => x.slot == slot);
      const ind = this.selectedSlot.indexOf(selectedItem);
      if (ind > -1) {
        this.selectedSlot.splice(ind, 1);
      }
    }
  }


  public onSubmit() {
    this.submitted = true;
    if (!this.reservationForm.valid) {
      console.log("Form submit")
      return;
    }
    if (!this.selectedSlot || this.selectedSlot.length < 1) {
      Swal.fire({
        icon: 'error',
        title: 'Required',
        text: 'Please select atleat one time slot',
        showConfirmButton: true
      });
      return;
    }
    this.selectedSlot.sort((a, b) => (a.id > b.id) ? 1 : -1);
    //check serial
    if (!(this.selectedSlot[0].id + this.selectedSlot.length - 1 == this.selectedSlot[this.selectedSlot.length - 1].id)) {
      Swal.fire({
        icon: 'error',
        title: 'Reservation',
        text: 'Please select time slot in sequence...',
        showConfirmButton: true
      });
      return;
    }
    this.reservationrequestModel.chargePointEvseId = this.actionService.chargePointEVSEIdforAction;
    this.reservationrequestModel.reason = this.reservationForm.controls.reason.value;
    if (!this.isRfid) {
      this.reservationrequestModel.userId = this.reservationForm.controls.tagId.value;
    }
    if (this.isRfid) {
      this.reservationrequestModel.rfidTagId = this.reservationForm.controls.tagId.value;
    }
    const reservationTime = new ReservationRequestTime();

    this.currentDate = new Date(this.reservationForm.controls.reservationDate.value);

    this.currentDate.setHours(+this.selectedSlot[0].slot.split(":")[0], +this.selectedSlot[0].slot.split(":")[1], 0, 0);
    reservationTime.startAt = Common.getEPOCH(this.currentDate);
    this.currentDate.setHours(+this.selectedSlot[this.selectedSlot.length - 1].slot.split(":")[0], +this.selectedSlot[this.selectedSlot.length - 1].slot.split(":")[1], 0, 0);
    this.currentDate.setTime(this.currentDate.getTime() + (this.actionService.reservationTariff.minDurationHours * 60 * 60 * 1000));
    reservationTime.endAt = Common.getEPOCH(this.currentDate);
    this.reservationrequestModel.reservationTime.push(reservationTime);
    this.submitted = false;
    if (!this.isRfid) {
      this.reservationService.saveReservation(this.reservationrequestModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: res.message,
              showConfirmButton: true
            });
            this.onReset();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.reservationrequestModel.reservationTime = [];
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorResponse.error.message,
            showConfirmButton: true
          });
        }
      );
    } else {
      this.reservationService.saveRfidReservation(this.reservationrequestModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: res.message,
              showConfirmButton: true
            });
            this.onReset();
          }
        },
        (error) => {
          this.reservationrequestModel.reservationTime = [];
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.errors[0]?.message[0],
            showConfirmButton: true
          });
        }
      );
    }
  }
}


class Itimeslot {
  id: number;
  slot: string;
  displaySlot: string;
  userInfo: string;
  chargePointReservationId: number;
  isOccupied: boolean;
  isAvailable: boolean;
  isClicked: boolean;
}
class SelectedSlot {
  id: number;
  slot: string;
}
