import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: 'app',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        data: { breadcrumb: 'Home' }
    },
    {
        path: 'browser-upgrade',
        loadChildren: () => import('./pages/blank-page/blank-page.module').then(m => m.BlankPageModule),
        data: { breadcrumb: { skip: true, alias: 'mentorAdd' } }
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        data: { breadcrumb: { skip: true, alias: 'mentorAdd' } }
    },
    {
        path: 'faq',
        loadChildren: () => import('../app/pages/faq/faq.module').then(m => m.FaqModule),
        data: { breadcrumb: 'FAQ', }
    },
    // {
    //     path: 'public/evse/:qrCodeId',
    //     component: PublicEvseComponent,
    //     data: { breadcrumb: { skip: true, alias: 'mentorAdd' } }
    // },
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: '**', redirectTo: 'app' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
