import { Injectable } from '@angular/core';
import { Common } from '../common/common';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../common/api-urls';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { ResponseModel } from '../models/response-model';
import { ChargePointResponseModel } from '../models/charge-point/charge-point-response';
import { ChargePointRequestModel } from '../models/charge-point/charge-point-request';
import { ChargePointUpdateModel } from '../models/charge-point/charge-point-update';
import { ValueDisplayString } from '../models/value-display-string';
import { ActionLog, ChangeHistroyLogsResponse, CommunicationLog, StatusLog } from 'src/app/models/charge-point-logs-vm';
import { EVSEMaxPower } from 'src/app/models/evse-max-power';
import { PartnerListVM } from '../models/partners/partner-list';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChargingSessionsListVM } from '../models/activity/charging-sessions-list-vm';
import { map } from 'rxjs/operators';
import { AmenityResponse } from '../models/amenity/amenity-response';
import { AgencyResponseVM } from '../models/agency/agency-response';
import { SessionReportsModel } from '../models/session-report/session-report-request';
import { ReservationListVM } from '../models/reservation-list/reservation-list';
import { CarbonCreditAgencyListVM } from '../models/carbon-credit/carbon-credit-agency-list-vm';

import { RequestTransferChargePoint } from '../models/charge-point/request-transfer-charge-point';
import { UpdateTransfterRequestChargePoint } from '../models/charge-point/update-transfter-request-charge-point';
import { SearchUserRequest, SearchUserResponse } from '../models/user/search-user-response';
import { CompactSharedRequestListVM } from '../models/compact-charge-point/compact-shared-request-list-vm';
import { UnlockConnectorRequest } from '../models/charge-point/unlock-connector-request';
import { PaginationRequestModel } from '../models/general/pagination-request-model';
import { ChannelType } from '../models/admin/channel-type';
import { TransferRequestVm } from '../models/charge-point/transfer-request-vm';
import { ChannelChangedCheck } from '../models/charge-point/channel-changed-check';
import { ChargePointTypeVM } from '../models/charge-point/charge-point-type-vm';


@Injectable({
  providedIn: 'root'
})
export class ChargePonitService {

  constructor(private http: HttpClient) { }
  chargePointId: number;
  chargePointName: string;
  chargePointType: string;
  chargePointEVSEIdforAction: number;
  getChargePoints(): Observable<ResponseModelList<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ChargePointResponseModel>>(ApiUrls.chargePoint, options);
  }

  getChargePointsPagination(gridState): Observable<ResponseModelListForPaging<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargePointResponseModel>>(ApiUrls.chargePointPagination, gridState, options);
  }

  getChargePoint(chargePointId: number): Observable<ResponseModel<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChargePointResponseModel>>(ApiUrls.chargePoint + '/' + chargePointId, options);
  }

  createChargePoint(chargePoint: ChargePointRequestModel): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<void>>(ApiUrls.chargePoint, chargePoint, options);
  }

  isChannelChangedCheck(chargePointId): Observable<ResponseModel<ChannelChangedCheck>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChannelChangedCheck>>(ApiUrls.isChannelChangedCheck.replace('{chargePointId}', chargePointId), options);
  }

  updateChargePoint(chargePoint: ChargePointUpdateModel, chargePointId: number): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<void>>(ApiUrls.chargePoint + '/' + chargePointId, chargePoint, options);
  }

  deleteChargePoint(chargePointId: number): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModel<string>>(ApiUrls.chargePoint + '/' + chargePointId, options);
  }
  // deleteImages(chargePointImagePathId: number): Observable<ResponseModel<any>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.delete<ResponseModel<any>>(ApiUrls.chargePoint + '/' + chargePointImagePathId, options);
  // }
  getChargePointStatus(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.chargePoint + '/status', options);
  }

  getChargePointNetworkTypes(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.chargePoint + '/network-types', options);
  }

  getChargePointTypes(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.chargePoint + '/types', options);
  }

  getChargePointProtocols(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.chargePoint + '/ocpp/network-protocols', options);
  }

  getChargePointPartners(): Observable<ResponseModelList<PartnerListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<PartnerListVM>>(ApiUrls.getPartners, options);
  }

  getChargePointSession(gridState: SessionReportsModel): Observable<ResponseModelListForPaging<ChargingSessionsListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargingSessionsListVM>>(ApiUrls.sessionHistory, gridState, options);
  }

  getChargePointActionLogs(chargePointId: number): Observable<ResponseModelList<ActionLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ActionLog>>(ApiUrls.chargePointActionLog + chargePointId, options);
  }

  getChargePointCommunicationLogs(chargePointId: number, gridState): Observable<ResponseModelListForPaging<CommunicationLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<CommunicationLog>>(ApiUrls.chargePointCommunicationnLog.replace("{chargePointId}", String(chargePointId)), gridState, options);
  }

  getChargePointHardwareLogs(chargePointId: number): Observable<ResponseModelList<StatusLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<StatusLog>>(ApiUrls.chargePointHardwareLog + chargePointId, options);
  }

  getChargePointNetworkLogs(chargePointId: number): Observable<ResponseModelList<StatusLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<StatusLog>>(ApiUrls.chargePointNetworkLog + chargePointId, options);
  }

  getEVSEByMaxPower(): Observable<ResponseModelList<EVSEMaxPower>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<EVSEMaxPower>>(ApiUrls.chargePointEVSEByMaxPower, options);
  }
  // getReservationData(chargePointId): Observable<ResponseModelList<any>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.get<ResponseModelList<any>>(ApiUrls.reservationPagination.replace('chargePointId', chargePointId), options);
  // }
  getReservationsListPagination(customeState): Observable<ResponseModelListForPaging<ReservationListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ReservationListVM>>(ApiUrls.reservationPagination, customeState, options);
  }
  getChargePointReqResLogs(chargePointId: number, gridState: PaginationRequestModel): Observable<ResponseModelListForPaging<StatusLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<StatusLog>>(ApiUrls.reqResLogByChargePointId.replace('{chargePointId}', String(chargePointId)), gridState, options);
  }

  getChargePointDetailsLogs(ocppClientName: string, chargeBox: string, gridState: PaginationRequestModel): Observable<ResponseModelListForPaging<StatusLog>> {
    const options = { headers: Common.getAuthorizeHeaderForNode() };
    return this.http.post<ResponseModelListForPaging<StatusLog>>(ApiUrls.detailsChargePointId
      .replace('{ocppClientName}', String(ocppClientName)).replace('{chargeBox}', String(chargeBox))
      , gridState, options);
  }
  getChargePointsByType(chargePointType: string): Observable<ResponseModelList<ChargePointTypeVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ChargePointTypeVM>>(ApiUrls.getChargePointByType
      .replace('{chargePointType}', String(chargePointType)), options);
  }
  public downloadSessionDataExcel(chargePointId: number, gridStateData) {
    return this.getExportSessionlistFileEndpoint(chargePointId, gridStateData).toPromise();
  }
  public getExportSessionlistFileEndpoint(chargePointId, gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadSessionDataByChargePointIdExcelfile.replace('{chargePointId}', String(chargePointId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }
  public downloadRequestResponseDataExcel(chargePointId: number, gridStateData) {
    return this.getExportRequestResponselistFileEndpoint(chargePointId, gridStateData).toPromise();
  }

  public getExportRequestResponselistFileEndpoint(chargePointId, gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadRequestResponceDataByChargePointIdExcelfile.replace('{chargePointId}', String(chargePointId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }
  public downloadCommunicationLogDataExcel(chargePointId: number, gridStateData) {
    return this.getExportCommunicationFileEndpoint(chargePointId, gridStateData).toPromise();
  }
  public getExportCommunicationFileEndpoint(chargePointId, gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadCommunicationLogByChargePointIdExcelfile.replace('{chargePointId}', String(chargePointId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }

  getAmenities(): Observable<ResponseModelList<AmenityResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<AmenityResponse>>(ApiUrls.clientAmenityList, options);
  }

  getChargePointsV3(): Observable<ResponseModelList<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ChargePointResponseModel>>(ApiUrls.chargePointV3, options);
  }
  getChildChannels(channelId, channelType): Observable<ResponseModelList<AgencyResponseVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<AgencyResponseVM>>(ApiUrls.getChildChannelList.replace('{channelId}', channelId).replace('{channelType}', channelType), options);
  }

  getParentAgencyNameList(type): Observable<ResponseModel<AgencyResponseVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<AgencyResponseVM>>(ApiUrls.getParentAgencyListByType.replace('{agencyType}', type), options);
  }
  getLoaUserList(): Observable<ResponseModel<ChannelType>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChannelType>>(ApiUrls.getLoaUserList, options);
  }
  getChargePointListByCRM(gridState, userId): Observable<ResponseModelListForPaging<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargePointResponseModel>>(ApiUrls.chargePointListByCRM
      .replace('{userId}', userId), gridState, options);
  }
  getChargePointListByChannel(gridState, channelId, channelType): Observable<ResponseModelListForPaging<ChargePointResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargePointResponseModel>>(ApiUrls.chargePointListByChannel
      .replace('{channelId}', channelId).replace('{channelType}', channelType), gridState, options);
  }

  public downloadChargePointExcel(gridStateData) {
    return this.getExportChargePointEndpoint(gridStateData).toPromise();
  }
  public getExportChargePointEndpoint(gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadChargePointExcelfile, gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }
  uploadImage(chargePointId, chargePointImages: FormData): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.uploadChargePointImages.replace('{chargePointId}', String(chargePointId)), chargePointImages, options);
  }
  getChangeHistoryLog(gridStateData): Observable<ResponseModelListForPaging<ChangeHistroyLogsResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChangeHistroyLogsResponse>>(ApiUrls.chargePointHistoryLogs, gridStateData, options);
  }

  getCarbonCreditAgency(): Observable<ResponseModelList<CarbonCreditAgencyListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<CarbonCreditAgencyListVM>>(ApiUrls.getCarbonCreditAgency, options);
  }
  getShareRequestList(chargePointId: number): Observable<ResponseModelList<CompactSharedRequestListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelListForPaging<CompactSharedRequestListVM>>(ApiUrls.compactChargerSharedRequestList.replace('{chargePointId}',String(chargePointId)), options);
  }
  public setTransfterRequestUser(chargePointId, requestTransfer :RequestTransferChargePoint): Observable<ResponseModel<TransferRequestVm>> {
    const options = { headers: Common.getAuthorizeHeader() };
    const url = ApiUrls.chargerTransferRequest.replace('{chargePointId}', chargePointId);
    return this.http.put<ResponseModel<TransferRequestVm>>(url, requestTransfer, options);
  }
  public removeTransfterRequestUser(requestTransfer :UpdateTransfterRequestChargePoint): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.updateTransferRequest, requestTransfer, options);
  }
  public searchUserByEmailOrPhone(searchRequest:SearchUserRequest): Observable<ResponseModel<SearchUserResponse>> {
    const options = { headers: Common.getAuthorizeHeader() }; 
    return this.http.post<ResponseModel<SearchUserResponse>>(ApiUrls.userSearchByPhoneEmail, searchRequest, options);
  }
  public unlockConnector(unlockConnector:UnlockConnectorRequest): Observable<ResponseModel<UnlockConnectorRequest>> {
    const options = { headers: Common.getAuthorizeHeader() }; 
    return this.http.post<ResponseModel<UnlockConnectorRequest>>(ApiUrls.unlockConnectorByEvseId,unlockConnector, options);
  }
  private subjectName = new BehaviorSubject<boolean>(false);
  public tariffUpdate = new BehaviorSubject<boolean>(false);
  public zoneUpdate = new BehaviorSubject<boolean>(false);
  public statusUpdate = new BehaviorSubject<string>('');

  sendCountUpdate(message: boolean) {
    this.subjectName.next(message);
  }
  getCountUpdate(): Observable<boolean> {
    return this.subjectName.asObservable();
  }

  sendStatusUpdate(message: string) {
    this.statusUpdate.next(message);
  }
  getStatusUpdate(): Observable<string> {
    return this.statusUpdate.asObservable();
  }

  sendTariffUpdate(message) {
    this.tariffUpdate.next(message);
  }
  getTariffUpdate(): Observable<boolean> {
    return this.tariffUpdate.asObservable();
  }

  sendZoneUpdate(message) {
    this.zoneUpdate.next(message);
  }
  getZoneUpdate(): Observable<boolean> {
    return this.zoneUpdate.asObservable();
  }
  setChargePointDetails(data)
  {
    this.chargePointId = data.chargePointId;
    this.chargePointName = data.chargePointName
  }
  getChargePointDetails(){
    if(this.chargePointId != null){
      const chargePointData = { chargePointId:this.chargePointId,name:this.chargePointName,display:this.chargePointName,value:this.chargePointId}
      this.clearData();
      return chargePointData;
    }
  }
  setChargePointType(data)
  {
    this.chargePointType = data;
  }
  getChargePointType(){
      return this.chargePointType;
  }
  clearData() {
    this.chargePointId = null;
    this.chargePointName = null;
  }
}
