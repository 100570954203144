export class AddCrmUser
 {
    public firstName: string;
    public lastName: string;
    public email: string;
    public password: string;
    public confirmPassword: string;
    public phone: number;
    public role : string;
    public userTagMaxLimit:number;
}
