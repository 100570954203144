export class CrmUsersListVM {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    emailVerifiedAt: number;
    phone: string;
    phoneVerifiedAt: number;
    partnerId: number;
    partnerName: string;
    statusDisplay: string;
    createdDate: Date;
    isAutoRegister: boolean;
    createdBy: UserCreatedBy;
    profileImageThumbUrl: string;
    balanceAmount: string;
    profileImageUrl: string;
    totalAmountPaid: number;
    totalKwhCharged: number
    lastChargingSessionDate : number;
    lastChargingSessionId : number;
    userTagMaxLimit:number;

}

export class UserCreatedBy {
    createdBy: number;
    firstName: string;
    lastName: string;
    deviceDisplay: string;
    deviceCode: string;
    email: string;
}
