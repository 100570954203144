import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { LocalStorageKeys, RouteUrl} from '../common/constant';
import { AuthService } from './auth.service';
import { LoginResponseV2 } from '../models/auth/login-response';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userData: LoginResponseV2 = JSON.parse(localStorage.getItem(LocalStorageKeys.loginResponse));
    if (userData) {
      if (route.data.roles && route.data.roles.indexOf(userData.role) === -1) {
        // if(userData.role == AdminRoles.msfAnalyst || userData.role == AdminRoles.sfAnalyst || userData.role == AdminRoles.distributorAnalyst || userData.role == AdminRoles.dealerAnalyst ){
        //   this.router.navigateByUrl(RouteUrl.shareReports);
        // }else if(userData.role == AdminRoles.landOwnerAgencyAnalyst){
        //   this.router.navigateByUrl(RouteUrl.sessionsReport);
        // }else{
        //   this.router.navigateByUrl(RouteUrl.dashboard);
        // }
        this.router.navigateByUrl(RouteUrl.dashboard);
         return false;
      }
      return true;
    }
    else {
      this.router.navigateByUrl(RouteUrl.login);
      return false;
    }
  }
}
