import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ValueDisplayString } from '../../../models/value-display-string';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { ToastService } from '../../../services/toast.service';
import { TriggerMessage } from '../../../models/charge-point-actions/trigger-message';
import Swal from 'sweetalert2';

import { DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-trigger-message',
  templateUrl: './trigger-message.component.html',
  styleUrls: ['./trigger-message.component.scss']
})
export class TriggerMessageComponent implements OnInit {

  triggerMessageForm: UntypedFormGroup;
  triggerMessageModel: TriggerMessage;
  public messageTypes: ValueDisplayString[] = [];
  public defaultmessageType: ValueDisplayString = { display: 'Type', value: null };
  submitted= false;

  constructor(
    private actionService: ChargingActionsService,
    private dialogRef: DynamicDialogRef,
    private toasterService: ToastService) { }

  ngOnInit(): void {
    this.triggerMessageForm = new UntypedFormGroup({
      triggerMessage: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
    });

    this.getMessageType();
  }

  getMessageType(): void {
    this.actionService.getMessageTypes().subscribe(
      (res) => {
        if (res.returnStatus) {
          this.messageTypes = res.data;
        }
        else {
          this.toasterService.showError('Message Type not found !');
        }
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.triggerMessageForm.valid) {
      this.triggerMessageModel = this.triggerMessageForm.value;
      this.triggerMessageModel.triggerMessage = this.triggerMessageForm.controls.triggerMessage.value;
      this.actionService.triggerMessage(this.triggerMessageModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          else {
            this.submitted = false;
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: res.message,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      return
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Error',
      //   text: 'Please fill required details',
      //   showConfirmButton: true,
      //   confirmButtonText: 'OK'
      // });
    }
  }

  onReset(): void {
    this.triggerMessageForm.reset();
    this.dialogRef.close();
  }

}
