import { Common } from '../common/common';
import { HttpClient } from '@angular/common/http';
//import { DialogService } from '@progress/kendo-angular-dialog';
import { ClientService } from './client.service';
import { DialogService} from 'primeng/dynamicdialog';
import { ResponseModelList } from '../models/response-model-list';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common/api-urls';
import { Injectable } from '@angular/core';
import { ValueDisplay } from '../models/value-display';



@Injectable({
    providedIn: 'root'
})

export class CommonService {
    index = 1;
    constructor(
       private primengDialog: DialogService,
        private http: HttpClient,
       private dialogService: DialogService,
        private clientService: ClientService) { }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public openStatusDialogForm(component, dtitle: string, width: number) {
       return this.primengDialog.open(component, {
          header: dtitle,
          width: '40%',
        });
    }

    getFormatedDateTime(datetime): string {
        return Common.getFormatedDateTime(datetime);
    }

    getClientLogo(): string {
        let logoFilePath;
        if (Common.loginResponseModel.clientKey) {
            this.clientService.getClientLogo(Common.loginResponseModel.clientKey).subscribe(
                (res) => {
                    logoFilePath = res.data.logoFilePath;
                    return logoFilePath;
                }
            );
        }
        return logoFilePath;
    }

    getDataByFilter(filterKey,filterValue): Observable<ResponseModelList<ValueDisplay>> {
      const options = { headers: Common.getAuthorizeHeader() };
       return this.http.get<ResponseModelList<ValueDisplay>>(ApiUrls.userFilter.replace('{$filterKey}',filterKey).replace('{$filterValue}',filterValue), options);
    }

}
