import { Component, OnInit } from '@angular/core';
import { Reason } from '../../../models/charge-point-actions/reason-for-manual-start';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import Swal from 'sweetalert2';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-clear-cache',
  templateUrl: './clear-cache.component.html',
  styleUrls: ['./clear-cache.component.scss']
})
export class ClearCacheComponent implements OnInit {
  submitted = false;

  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  clearCacheModel: Reason;

  clearCacheForm: UntypedFormGroup;
  ngOnInit(): void {
    this.clearCacheForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.clearCacheForm.valid) {
      this.clearCacheModel = this.clearCacheForm.value;
      this.actionService.clearCache(this.clearCacheModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          this.dialogRef.close();
        },
        (error) => {
          this.submitted = false;
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
return;
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Error',
      //   text: 'Please fill required details',
      //   showConfirmButton: true,
      //   confirmButtonText: 'OK'
      // });
    }
  }

  onReset(): void {
    this.clearCacheForm.reset();
    this.dialogRef.close();
  }

}
