import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ChargingActionsService } from 'src/app/services/charging-actions.service';
import { Reset } from 'src/app/models/charge-point-actions/reset';


import Swal from 'sweetalert2';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ValueDisplayString } from 'src/app/models/value-display-string';


@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  submitted = false;

  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  resetForm: UntypedFormGroup;
  resetModel: Reset;
  public types: ValueDisplayString[] = [];
  public defaultType: ValueDisplayString = { display: 'Type', value: null };

  ngOnInit(): void {
    this.resetForm = new UntypedFormGroup({
      resetType: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
    });
    this.getResetTypes();
  }

  getResetTypes(): void {
    this.actionService.getResetTypes().subscribe(
      (res) => {
        if (res.returnStatus) {
          this.types = res.data;
        }
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.resetModel = this.resetForm.value;
      this.resetModel.resetType = this.resetForm.controls.resetType.value;
      this.actionService.reset(this.resetModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          this.dialogRef.close();
        },
        (error) => {
          this.submitted = false;
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
     return
    }
  }

  onReset(): void {
    this.resetForm.reset();
    this.dialogRef.close();
  }

}
