import { TariffDurationVM } from "./tariff-request";

export class TariffResponseVM {
    tariffName: string;
    tariffDescription: string;
    tariffType: string;
    connectionFee: number;
    tariffTypeDisplay : string;
    connectionFeeGstPer : number;
    tariffDuration : TariffDurationVM[];
    parkingSlot;
    tariffId: number;
    totalAmountPerUnit : number;
    paymentGatewayPercentage : number;
    gstPercentage : number;
    isCustomConnectionFeeGst : boolean
    totalDurationCost : number;
    connectionFeeGstAmount : number;
    convenienceFee : number;
    convenienceFeeGst : number;

}
