import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { RFIDTagsVM } from '../models/rfid-tag/rfid-tags-vm';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/response-model';
import { RFIDTagStatus } from '../models/rfid-tag/rfid-tag-status';
import { RfidTagCreateRequest } from '../models/rfid-tag/rfid-tag-create-request';
import { Observable } from 'rxjs';
import { RfidTagUpdateRequest } from '../models/rfid-tag/rfid-tag-update-request';
import { BulkRFIDResponse } from '../models/rfid-tag/bulk-rfid-response';
import { ChargingSessionsListVM } from '../models/activity/charging-sessions-list-vm';

import { RFIDTagsListVM } from '../models/rfid-tag/rfid-tags-list-vm';
import { RFIDTagNamesVM } from '../models/rfid-tag/rfid-tag-names';

@Injectable({
  providedIn: 'root'
})
export class RFIDTagsService {

  constructor(private http: HttpClient) { }

  getrfidtagsPagination(customState): Observable<ResponseModelListForPaging<RFIDTagsListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<RFIDTagsListVM>>(ApiUrls.rfidTagPagination,customState,options);
  }
  getrfidtags(): Observable<ResponseModelList<RFIDTagsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<RFIDTagsVM>>(ApiUrls.rfidTag, options);
  }
  getRfidtagById(rfidTagId: number): Observable<ResponseModel<RFIDTagsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<RFIDTagsVM>>(ApiUrls.rfidTag + '/' + rfidTagId, options);
  }

  createRFIDTag(rfidTagModel: RfidTagCreateRequest): Observable<ResponseModel<RFIDTagsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<RFIDTagsVM>>(ApiUrls.rfidTag, rfidTagModel, options);
  }

  importRFIDTag(bulkRFIDTagModel: RfidTagCreateRequest[]): Observable<ResponseModel<BulkRFIDResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<BulkRFIDResponse>>(ApiUrls.rfidTag + '/import', bulkRFIDTagModel, options);
  }

  updateRFIDTag(rfidTagModel: RfidTagUpdateRequest, rfidTagId: number): Observable<ResponseModel<RFIDTagsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<RFIDTagsVM>>(ApiUrls.rfidTag + '/' + rfidTagId, rfidTagModel, options);
  }

  deleteRFIDTag(rfidTagId: number): Observable<ResponseModel<RFIDTagsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModel<RFIDTagsVM>>(ApiUrls.rfidTag + '/' + rfidTagId, options);
  }

  getRFIDTagStatus(): Observable<ResponseModelList<RFIDTagStatus>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<RFIDTagStatus>>(ApiUrls.rfidTagStatus, options);
  }

  getChargingSessionListByRfid(gridState): Observable<ResponseModelListForPaging<ChargingSessionsListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargingSessionsListVM>>(ApiUrls.sessionHistory, gridState, options);
  }
  // public DownloadSessionDataExcel(rfidTagId: number, gridStateData) {
  //   return this.getExportSessionFileEndpoint(rfidTagId,gridStateData).toPromise();
  // }
  // public getExportSessionFileEndpoint(rfidTagId,gridStateData): Observable<any> {
  //   const gridState = JSON.parse(JSON.stringify(gridStateData));
  //   return this.http.post(ApiUrls.downloadSessionDataByRfidExcelfile.replace('{rfidTagId}', String(rfidTagId)), gridState, {  headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response: any) => {
  //         return response;
  //     }));
  // }
  getrfidtagsForReport(filterKey,filterValue): Observable<ResponseModelList<RFIDTagNamesVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<RFIDTagNamesVM>>(ApiUrls.reportFilter.replace('{$filterKey}',filterKey).replace('{$filterValue}',filterValue), options);
  }
}
