import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { StartSession } from '../../../models/charge-point-actions/start-session';

import { CrmUsersListVM } from '../../../models/user/crm-users-list';
import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../services/toast.service';
import Swal from 'sweetalert2';

import { TariffService } from '../../../services/tariff.service';
import { RouteUrl } from '../../constant';
import { TariffResponseVM } from '../../../models/tariff/tariff-response';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Common } from '../../common';
import { EvseService } from 'src/app/services/evse.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { ValueDisplay } from 'src/app/models/value-display';

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss']
})
export class StartSessionComponent implements OnInit, OnDestroy, AfterViewInit {
  evseTariffType: string;
  defaultDuaration = new Date('10-02-2022 00:00');
  chargingOptions: { label: string; value: string; }[];
  freeDuration: boolean;
  freeKwh: boolean;
  source;
  usersData: ValueDisplay[];
  isAmount: boolean;
  constructor(
    private actionService: ChargingActionsService,
    private dialogRef: DynamicDialogRef,
    private userService: UserService,
    private toasterService: ToastService,
    private tariffService: TariffService,
    private evseService: EvseService,
    private commonService: CommonService,
  ) {
    this.data = this.userList.slice();
    this.subscriptionName = this.evseService.getTariffType().subscribe(message => {
        this.evseTariffType = message;
        console.log('this.evseTariffType', this.evseTariffType);
      });
    this.chargingOptions = [{ label: 'Kwh', value: 'Kwh' }, { label: 'Duration', value: 'Duration' }, { label: 'Amount', value: 'Amount' }];
  }
  submitted = false;
  startSessionForm: UntypedFormGroup;
  startSessionModel: StartSession = new StartSession();
  get routes() { return RouteUrl; }
  public userList: CrmUsersListVM[] = [];
  public data: CrmUsersListVM[] = [];
  public defaultuser: CrmUsersListVM = new CrmUsersListVM();

  public tariffs: TariffResponseVM[] = [];
  public defaulttarrif: TariffResponseVM = new TariffResponseVM();
  @ViewChild('mobileUserRfId', { read: ElementRef }) mobileUserRfId: ElementRef;

  isTariffTypeFree = false;
  isTariffTypeDuration = false;
  isTariffTypeUnit = false;
  subscriptionName: Subscription;
  ngOnInit(): void {
    this.startSessionForm = new UntypedFormGroup({
      userId: new UntypedFormControl(null, Validators.required),
      option: new UntypedFormControl(null, Validators.required),
      kwh: new UntypedFormControl(0),
      minutes: new UntypedFormControl(0),
      amount: new UntypedFormControl(0),
      reason: new UntypedFormControl('', Validators.required),
    });

    this.defaultuser.email = 'Select User';
    this.defaultuser.userId = null;

    this.defaulttarrif.tariffName = 'Select Tariff Plan';
    this.defaulttarrif.tariffId = null;

    //this.getUserList();
    //this.getTariffPlan();

    // if (this.evseTariffType === TariffType.TariffTypeFree) {
    //   this.isTariffTypeFree = true;
    //   this.isTariffTypeDuration = false;
    //   this.isTariffTypeUnit = false;

    // }else if (this.evseTariffType === TariffType.TariffTypeFixed) {
    //   this.isTariffTypeDuration = true;
    //   this.isTariffTypeUnit = true;
    // }else {
    //   this.isTariffTypeFree = false;
    //   this.isTariffTypeDuration = false;
    //   this.isTariffTypeUnit = false;
    // }

  }

  ngAfterViewInit(): void {
    this.source = fromEvent(this.mobileUserRfId.nativeElement, 'keyup');
    this.source.pipe(debounceTime(1200)).subscribe(c => {
      this.getUsersDetail('users', c.target.value);
    });
  }

  getUsersDetail(filterKey, filterValue): void {
    if (filterValue != "") {
      this.commonService.getDataByFilter(filterKey, filterValue).subscribe(response => {

        if (response.returnStatus) {
          this.usersData = response.data;

        }
      },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.error.errors[0].message[0],
            showConfirmButton: true
          });
        });
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.startSessionForm.valid) {
      this.startSessionModel = this.startSessionForm.value;
      this.startSessionModel.userId = this.startSessionForm.controls.userId.value;
      // this.startSessionModel.tariffId = this.startSessionForm.controls.tariffId.value.tariffId;
      if (this.freeDuration) {
        this.startSessionModel.minutes = Common.hhmmToMinutes(this.startSessionForm.controls.minutes.value.getHours() + ':' + this.startSessionForm.controls.minutes.value.getMinutes())
        this.startSessionModel.kwh = 0;
        this.startSessionModel.amount = 0;
      } else if (this.isAmount) {
        this.startSessionModel.minutes = 0;
        this.startSessionModel.kwh = 0;
      } else {
        this.startSessionModel.minutes = 0;
        this.startSessionModel.amount = 0;
      }
      // if (this.isTariffTypeFree) {
      //   this.startSessionModel.kwh = 0;
      //   this.startSessionModel.minutes = 0;
      // }
      if (this.startSessionModel.kwh == null || this.startSessionModel.kwh == undefined) {
        this.startSessionModel.kwh = 0;
      }
      if (this.startSessionModel.minutes == null || this.startSessionModel.minutes == undefined) {
        this.startSessionModel.minutes = 0;
      }
      if (this.startSessionModel.amount == null || this.startSessionModel.amount == undefined) {
        this.startSessionModel.amount = 0;
      }

      this.actionService.checkReservation(this.startSessionModel.userId).subscribe(checkresv => {
        if (checkresv.returnStatus && checkresv.data) {
          if (checkresv.data.isReservation == false) {
            this.actionService.startSession(this.startSessionModel).subscribe(res => {
              if (res.returnStatus) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Success',
                  text: res.message,
                  showConfirmButton: false,
                  timer: 4000
                });
                this.dialogRef.close();
              }
              else {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Error',
                  text: res.message,
                  showConfirmButton: true,
                  confirmButtonText: 'OK'
                });
              }
              this.dialogRef.close();
              this.submitted = false;
            },
              (error) => {
                console.log(error);
                this.dialogRef.close();
              });
          }
          else if (checkresv.data.isReservation == true) {
            Swal.fire({
              title: 'Are you sure?',
              text: checkresv.data.message,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes'
            }).then((swalresult) => {
              if (swalresult.isConfirmed) {
                this.actionService.startSession(this.startSessionModel).subscribe(res => {
                  if (res.returnStatus) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Success',
                      text: res.message,
                      showConfirmButton: false,
                      timer: 4000
                    });
                    this.dialogRef.close();
                  }
                  else {
                    Swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: 'Error',
                      text: res.message,
                      showConfirmButton: true,
                      confirmButtonText: 'OK'
                    });
                  }
                  this.dialogRef.close();
                  this.submitted = false;
                },
                  (error) => {
                    console.log(error);
                    this.dialogRef.close();
                  });
              }
              else {
                this.dialogRef.close();
              }
            })
          }
        }
      },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        });
    }
    else {
      return;
    }
  }

  getUserList(): void {
    this.userService.getAvailableUsersForStartSession().subscribe(
      (res) => {
        if (res.returnStatus) {
          this.userList = res.data;
        }
        else {
          this.toasterService.showError('Users not found !');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getTariffPlan(): void {
    this.tariffService.getTariffList().subscribe(
      (response) => {
        if (response.returnStatus) {
          this.tariffs = response.data;
        }
      }
    );
  }

  handleChargingOption(e) {
    console.log(e);
    if (e.value == "Duration") {
      this.freeDuration = true;
      this.freeKwh = false;
      this.isAmount = false;
      this.startSessionForm.controls['minutes'].setValidators([Validators.required]);
      this.startSessionForm.controls['kwh'].clearValidators();
      this.startSessionForm.controls['amount'].clearValidators();

    } else if (e.value == "Kwh") {
      this.freeKwh = true;
      this.freeDuration = false;
      this.isAmount = false;
      this.startSessionForm.controls['kwh'].setValidators([Validators.required]);
      this.startSessionForm.controls['minutes'].clearValidators();
      this.startSessionForm.controls['amount'].clearValidators();
    } else {
      this.freeKwh = false;
      this.freeDuration = false;
      this.isAmount = true;
      this.startSessionForm.controls['amount'].setValidators([Validators.required]);
      this.startSessionForm.controls['minutes'].clearValidators();
      this.startSessionForm.controls['kwh'].clearValidators();
    }

    this.startSessionForm.controls['kwh'].updateValueAndValidity();
    this.startSessionForm.controls['minutes'].updateValueAndValidity();
    this.startSessionForm.controls['amount'].updateValueAndValidity();
  }

  handleFilter(value): void {
    if (value.filter != null) {
      this.data = this.userList.filter((s) => s.email.toLowerCase().indexOf(value.filter.toLowerCase()) !== -1);
    }

  }

  onReset(): void {
    this.startSessionForm.reset();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }

}
