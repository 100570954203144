<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="getDiagnosticForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Reason
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                            <div *ngIf="reason.invalid && (reason.touched || reason.dirty)" class="invalid-feedback ">
                                <span *ngIf="reason.errors?.required ">Reason is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Retries
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="number" max="3" min="0" formControlName="retries" class="form-control" />
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.required ">Retries is required
                                </span>
                            </div>
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.max ">Retries must be between 0 to 3
                                </span>
                            </div>
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.min ">Retries must be between 0 to 3
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Retry Interval (In Minutes)
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="number" min="1" formControlName="retryInterval" class="form-control" />
                            <div *ngIf="retryInterval.invalid && (retryInterval.touched || retryInterval.dirty)" class="invalid-feedback ">
                                <span *ngIf="retryInterval.errors?.required ">Interval is required
                                </span>
                            </div>
                            <div *ngIf="retryInterval.invalid && (retryInterval.touched || retryInterval.dirty)" class="invalid-feedback ">
                                <span *ngIf="retryInterval.errors?.min ">Interval must be greater than 0
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Start time
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-calendar formControlName="startTime" [showIcon]="true" appendTo=body [maxDate]="maxDate" 
                            [showTime]="true" dateFormat="dd-MM-yy" [showIcon]="true"></p-calendar>
                            <div *ngIf="startTime.invalid && (startTime.touched || startTime.dirty)" class="invalid-feedback ">
                                <span *ngIf="startTime.errors?.required ">Start Time is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Stop time
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-calendar formControlName="stopTime" [showIcon]="true" appendTo=body [maxDate]="maxDate" 
                            [showTime]="true" dateFormat="dd-MM-yy" [showIcon]="true"></p-calendar>
                            <div *ngIf="stopTime.invalid && (stopTime.touched || stopTime.dirty)" class="invalid-feedback ">
                                <span *ngIf="stopTime.errors?.required ">Stop Time is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" [disabled]="!getDiagnosticForm.valid" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Clear">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>