import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UnlockConnectorRequest } from 'src/app/models/charge-point/unlock-connector-request';
import { ChargePonitService } from 'src/app/services/charge-point.service';
import Swal from 'sweetalert2';
import { RouteUrl } from '../../constant';

@Component({
  selector: 'app-unlock-connector',
  templateUrl: './unlock-connector.component.html',
  styleUrl: './unlock-connector.component.scss'
})
export class UnlockConnectorComponent implements OnInit {
  constructor(
    private dialogRef: DynamicDialogRef,
    private chargePointService: ChargePonitService
  ) {}
  submitted = false;
  unlockConnectorForm: UntypedFormGroup;
  unlockConnectorRequest: UnlockConnectorRequest = new UnlockConnectorRequest();
  get routes() { return RouteUrl; }

  ngOnInit(): void {
    this.unlockConnectorForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.unlockConnectorForm.valid) {
      this.unlockConnectorRequest.chargePointEvseId = this.chargePointService.chargePointEVSEIdforAction;
      this.unlockConnectorRequest.reason = this.unlockConnectorForm.controls.reason.value;
      this.chargePointService.unlockConnector(this.unlockConnectorRequest).subscribe(res => {
        if (res.returnStatus) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            text: res.message,
            showConfirmButton: false,
            timer: 4000
          });
          this.dialogRef.close();
        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            text: res.message,
            showConfirmButton: true,
            confirmButtonText: 'OK'
          });
        }
        this.dialogRef.close();
        this.submitted = false;
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        });
    }
    else {
      return;
    }
  }
  onReset(): void {
    this.unlockConnectorForm.reset();
    this.dialogRef.close();
  }
}
