import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NfcSharedModule } from './nfc-shared.module';
import { AuthGuard } from './services/auth-guard.service';
import { ErrorInterceptor } from './helpers/error-interceptor';
import {
    MqttModule,
    IMqttServiceOptions
} from 'ngx-mqtt';

import { ToastrModule } from 'ngx-toastr';
import { PublicEvseComponent } from './common/components/public-evse/public-evse.component';
import { Common } from './common/common';
import { NgxImageCompressService } from 'ngx-image-compress';

declare let mqttHost;
declare let mqttPort;
declare let mqttprotocol;
declare let mqttClientId;

export const mqttServiceOption: IMqttServiceOptions = {
    hostname: mqttHost,
    port: mqttPort,
    protocol: mqttprotocol,
    clientId: mqttClientId + Common.newGuid()
};
import { NgxEchartsModule } from 'ngx-echarts';


@NgModule({
    imports: [
        
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        NfcSharedModule,
        MqttModule.forRoot(mqttServiceOption),
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
        NgxEchartsModule.forRoot({
          echarts: () => import('echarts'),
        }),
        
    ],
    declarations: [
        AppComponent, PublicEvseComponent
    ],
    providers: [Title, AuthGuard, DatePipe,NgxImageCompressService,DecimalPipe, {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
