import { ChargePointResponseModel } from '../charge-point/charge-point-response';
import { ConnectorResponseModel } from '../connector/connector-response';
import { ReservationTariff } from '../reservation-tariff/reservation-tariff';
import { TariffResponseVM } from '../tariff/tariff-response';

export class EVSEResponseModel {
    chargePointId: number;
    chargeBox: string;
    type: string;
    typeDisplay: string;
    tariff: TariffResponseVM;
    publicDiscountedTariff: TariffResponseVM;
    networkId: string;
    maxPower: number;
    tags: string;
    status: string;
    statusDisplay: string;
    maxCurrent: number;
    tariffId: number;
    publicDiscountedTariffId :number;
    phases: string;
    phasesDisplay: string;
    phaseRotation: string;
    phaseRotationDisplay: string;
    hardwareStatus: string;
    hardwareStatusDisplay: string;
    hardwareStatusInfo: string;
    chargePointEvseConnector: ConnectorResponseModel[];
    chargePointEvseId: number;
    qrCodeId: number;
    lastChargingSessionDate: number;
    lastChargingSessionId: number;
    enableReservation: boolean;
    enableOtp: boolean;
    reservationTariff: ReservationTariff;
    reservationTariffId: number;
    chargePoint: ChargePointResponseModel;
}
