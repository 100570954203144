
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from './common/components/loader/loader.component';
import { ResetComponent } from './common/components/reset/reset.component';
import { ClearCacheComponent } from './common/components/clear-cache/clear-cache.component';
import { GetDiagnosticComponent } from './common/components/get-diagnostic/get-diagnostic.component';
import { ResetSecurityProfileComponent } from './common/components/reset-security-profile/reset-security-profile.component';
import { RaiseSecurityProfileComponent } from './common/components/raise-security-profile/raise-security-profile.component';
import { CompositeScheduleComponent } from './common/components/composite-schedule/composite-schedule.component';
import { ReserveComponent } from './common/components/reserve/reserve.component';
import { TriggerMessageComponent } from './common/components/trigger-message/trigger-message.component';
import { UnlockComponent } from './common/components/unlock/unlock.component';
import { UpdateFirmwareComponent } from './common/components/update-firmware/update-firmware.component';
import { StartSessionComponent } from './common/components/start-session/start-session.component';
import { StopSessionComponent } from './common/components/stop-session/stop-session.component';



import { PayOutstandingComponent } from './common/components/pay-outstanding/pay-outstanding.component';
import { ChangeAvailabilityComponent } from './common/components/change-availability/change-availability.component';
import { ReservationComponent } from './common/components/reservation/reservation.component';
import { CancelReservationComponent } from './common/components/cancel-reservation/cancel-reservation.component';
import { DataTransferComponent } from './common/components/data-transfer/data-transfer.component';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';

import { SetChargingProfileComponent } from './common/components/set-charging-profile/set-charging-profile.component';
import { SetChargingProfileChargerComponent } from './common/components/set-charging-profile-charger/set-charging-profile-charger.component';
import { UnlockConnectorComponent } from './common/components/unlock-connector/unlock-connector.component';

@NgModule({
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
   
    MatDialogModule,
    MatButtonModule,
     
    PrimengModule,


  ],
  declarations: [
    LoaderComponent,
    ResetComponent,
    ClearCacheComponent,
    GetDiagnosticComponent,
    ResetSecurityProfileComponent,
    RaiseSecurityProfileComponent,
    CompositeScheduleComponent,
    StartSessionComponent,
    ReserveComponent,
    UnlockComponent,
    UpdateFirmwareComponent,
    TriggerMessageComponent,
    StopSessionComponent,
    PayOutstandingComponent,
    ChangeAvailabilityComponent,
    ReservationComponent,
    CancelReservationComponent,
    DataTransferComponent,
    SetChargingProfileComponent,
    SetChargingProfileChargerComponent,
    UnlockConnectorComponent
  ],
  exports: [
    LoaderComponent
  ],

})

export class NfcSharedModule { }
