import { HttpHeaders } from '@angular/common/http';
import { LoginResponseV2 } from '../models/auth/login-response';

import {verdeAdminMenu, AdminRoles } from './constant';

import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BaseMenu } from '../models/menu/base-menu';
import { saveAs } from 'file-saver';
import { EMSPLoginResponse } from '../models/auth/emsp-login-response';
import { ChargePointResponseModel } from '../models/charge-point/charge-point-response';
export class Common {
  static emailPattern = '^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+){1,}[\\s\\,]*)+$';
  static singleEmailPattern = '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  static passwordValidationPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[`~!@#$%^&*()\\-_=+\\[\\]{}\\\\|;:\'",<.>\\/?])[\\w`~!@#$%^&*()\\-_=+\\[\\]{}\\\\|;:\'",<.>\\/?]{6,}$';
  //static passwordValidationPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{6,}$';
  static phoneNumberValidationPattern = '^[0-9]{10}';
  static notAllowSpecialChars = '^[a-z A-Z - 0-9]+(-[a-z A-Z 0-9]+)?$';
  static allowNumberAndDecimalPoint = '^[0-9]\\d*(\\.\\d+)?$';
  static allowOnlyCharacters = '^[ a-zA-Z//]{2,30}$';
  static allowUnderscore = '^[a-zA-Z0-9_]*$';
  static namePattern = "^[a-zA-Z''_']+";
  static onlyNumber = '^[0-9 -]+$';
  static onlyNumbersValue = '^[0-9]+$';
  static panNumberValidationPattern = '[A-Z]{5}[0-9]{4}[A-Z]{1}';
  static gstNumberValidationPattern = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$';
  static accountNumberValidationPattern = '^[0-9]{9,18}$';
  static tanNumberValidationPattern = '[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}';
  static ifscCodeValidationPattern = '^[A-Z]{4}0[A-Z0-9]{6}$';
  static nameValidationPattern = '^[a-zA-Z]+(?: [a-zA-Z]+)*$';
  static allowFiftyCharacters = '^.{1,50}$';
  static notificationPattern = '^.{1,140}$';
  static token: string;
  static loginResponseModel: LoginResponseV2;
  static ocploginResponseModel: EMSPLoginResponse;
  static empsStatus: ChargePointResponseModel;
  static menulist: BaseMenu[] = verdeAdminMenu;
  private static countryStateCityAPIKey = 'amIybklBSlNoWm90Slo1QnVCcUQzUE8ybGVTVnpTVjhNZHN6OUJqUQ==';
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // filteredValues: any;
  // static partnerRights= false;
  static isNullOrEmpty(data): boolean {
    if (data) {
      return false;
    } else {
      return true;
    }
  }


  static isViewOnlyRights(): boolean {
    if (Common.loginResponseModel.role === AdminRoles.vmAnalyst
      || Common.loginResponseModel.role === AdminRoles.landOwnerAnalyst
      || Common.loginResponseModel.role === AdminRoles.landOwnerAgencyAnalyst
      || Common.loginResponseModel.role === AdminRoles.msfAnalyst
      || Common.loginResponseModel.role === AdminRoles.sfAnalyst
      || Common.loginResponseModel.role === AdminRoles.distributorAnalyst
      || Common.loginResponseModel.role === AdminRoles.dealerAnalyst
      || Common.loginResponseModel.role === AdminRoles.operator
    ) {
      return true;
    }
    else {
      return false;
    }
  }


  static omitSpecialChar(e) {
    let k;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 46 || (k >= 48 && k <= 57));
  }
  static omitSpecialCharAllowUnderscore(e) {
    let k;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 46 || k == 95 || (k >= 48 && k <= 57));
  }

  static isNumber(value): number {
    return isNaN(value) || value === '' || value == null ? 0 : parseFloat(value);
  }

  static getUTCDate(): string {
    return (new Date()).toUTCString();
  }

  static getCurrentEPOCH(): number {
    return Math.round(new Date().getTime() * 1000);
  }

  static getEPOCH(date: Date): number {
    return Math.round(date.getTime());
  }


  // static getEpochUTC(date: Date): number {
    
  //   return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  // }


  static getDateFromEPOCH(epoc): Date {
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(epoc);
    return date;
  }

  static hhmmToSeconds(time) {
    const a = time.split(':');
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
    return seconds;
  }
  static hhmmToMinutes(time) {
    const a = time.split(':');
    const minutes = (+a[0]) * 60 + (+a[1]);
    return minutes;
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  static getHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true'
    });
    return headers;
  }
  static getAuthorizeHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }
  static getAuthorizeHeaderOCPI(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
      'Access-Control-Allow-Credentials': 'true',
      'json-naming-strategy': 'camelcase',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }
  static getAuthorizeHeaderOCPIMultiPart(): HttpHeaders {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
      'Access-Control-Allow-Credentials': 'true',
      'json-naming-strategy': 'camelcase',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }
  static getAuthorizeHeaderForNode(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }
  static getAuthorizeMultiPartHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: 'Bearer ' + Common.loginResponseModel.accessToken
    });
    return headers;
  }
  static getCountryStateCityAPIHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'X-CSCAPI-KEY': `${Common.countryStateCityAPIKey}`
    });
    return headers;
  }
  static getFormatedDate(date) {
    if (date) {
      date = new Date(date);
      return date.toLocaleString();
    }
    return '';
  }
  static getFormatedDateTime(date): string {
    if (date) {
      date = new Date(date);
      return date.toLocaleString();
    }
  }
  static convertToLocalTimezone(date): Date {
    const newDate = new Date(date);
    //const dt = new Date();
    return new Date(newDate.toUTCString());
  }

  static formatCommunicationLog(element){
    const displayBody = JSON.parse(element.responseBody);
    if (element.command === 'COMMAND_LIST_METER_VALUES') {
      if (displayBody?.meterValue[0]?.timestamp?.millis) {
        displayBody['Date'] = this.getFormatedDate(displayBody?.meterValue[0]?.timestamp?.millis);
        delete displayBody.meterValue[0].timestamp;
      }
    } else if (element.command === 'COMMAND_LIST_STATUS_NOTIFICATION' || element.command === 'COMMAND_LIST_STOP_TRANSACTION') {
      if (displayBody?.timestamp?.millis) {
        displayBody['Date'] = this.getFormatedDate(displayBody?.timestamp?.millis);
        delete displayBody.timestamp;
      }
    } else if (element.command === 'COMMAND_LIST_START_TRANSACTION') {
      displayBody['Date'] = this.getFormatedDate(displayBody?.timestamp?.millis);
      if (displayBody?.idTagInfo?.expiryDate?.millis !== undefined) {
        displayBody.idTagInfo["tagExpiryDate"] = this.getFormatedDate(displayBody?.idTagInfo?.expiryDate?.millis);
        delete displayBody.idTagInfo.expiryDate;
      }
      delete displayBody.timestamp;
    }
    return JSON.stringify(displayBody);
  }
  static formatCommunicationDetailLog(element) {
    const displayBody = element.responseBody;
    if (element.command === 'METER_VALUE') {
      if (displayBody?.meterValue[0]?.timestamp) {
        displayBody['Date'] = this.getFormatedDate(displayBody?.meterValue[0]?.timestamp);
        delete displayBody.meterValue[0].timestamp;
      }
    } else if (element.command === 'STATUS_NOTIFICATION' || element.command === 'STOP_TRANSACTION') {
      if (displayBody?.timestamp) {
        displayBody['Date'] = this.getFormatedDate(displayBody?.timestamp);
        delete displayBody.timestamp;
      }
    } else if (element.command === 'START_TRANSACTION') {
      displayBody['Date'] = this.getFormatedDate(displayBody?.timestamp);
      if (displayBody?.idTagInfo?.expiryDate !== undefined) {
        displayBody.idTagInfo["tagExpiryDate"] = this.getFormatedDate(displayBody?.idTagInfo?.expiryDate);
        delete displayBody.idTagInfo.expiryDate;
      }
      delete displayBody.timestamp;
    }
    return displayBody;
  }

  // onFilter(event) {
  //   this.filteredValues = event.filteredValue;
  // }

  static saveAsExcelFile(buffer, fileName: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    import("file-saver").then(fileSaver => {
      // eslint-disable-next-line customRule/camelCaseVariableRule
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      // eslint-disable-next-line customRule/camelCaseVariableRule
      const EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  static convertKendoToPrimePayload(event) {

    const state = {
      skip: event.first,
      take: event.rows,
    };
     
    let customState;
    let send;
    if ((event != null || event != undefined)) {
      let sortDirection;
      if (event.sortField === undefined) {
        sortDirection = [];
      }
      else if (event.sortOrder == 1) {
        sortDirection = [
          {
            "dir": "asc",
            "field": event.sortField
          }
        ]
      }
      else {
        sortDirection = [
          {
            "dir": "desc",
            "field": event.sortField
          }
        ]
      }

      send = {
        group: [],
        skip: event.first,
        sort: sortDirection,
        take: event.rows
      };
      state.skip = event.first;
      state.take = event.rows;
      customState = send;
    }
    else {
      customState = state;
    }
    const filterData = event;
    const fileds = Object.keys(filterData.filters);
    const neededArray = [];
    const filterInner = [];
    const filterOuter = [];
    let prop;
    let f = 0;
    let i = 0;
    for (prop of fileds) {
      neededArray.push(filterData.filters[prop]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      f++;
    }
    neededArray.forEach(e => {
      e = e.map(item => {

        return {
          operator: item.matchMode,
          value: item.value,
          logic: item.operator,
        };

      });
      e.forEach(e1 => {
        for (prop of fileds) {
          e1['field'] = fileds[i];
        }
        if (e1['operator'] == "equals") {
          e1['operator'] = "eq"
        }
        else if (e1['operator'] == "notEquals") {
          e1['operator'] = "neq"
        }
        else if (e1['operator'] == "notContains") {
          e1['operator'] = "doesnotcontain"
        }
        else if (e1['operator'] == "startsWith") {
          e1['operator'] = "startswith"
        }
        else if (e1['operator'] == "endsWith") {
          e1['operator'] = "endswith"
        }

        //Date filters PrimeNG to Kendo
        else if (e1['operator'] == "dateIs") {
          e1['operator'] = "eq"
        }
        else if (e1['operator'] == "dateIsNot") {
          e1['operator'] = "neq"
        }
        else if (e1['operator'] == "dateBefore") {
          e1['operator'] = "lt"
        }
        else if (e1['operator'] == "dateAfter") {
          e1['operator'] = "gt"
        }

        if (e1['value'] != null) {

          filterInner.push({ "filters": e, "logic": e[0]['logic'] });
        }
      });
      i++;
    });
    filterOuter.push({ "filters": filterInner, "logic": "and" });
    send.filter = filterOuter[0];
    return customState;
  }
}

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0;
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });
    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }
    return null;
  };
}
