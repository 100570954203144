import { Component, OnInit } from '@angular/core';
import { ClientLogo } from '../../../models/client-logo';

import { Common } from '../../common';
import { ClientService } from '../../../services/client.service';
import { ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-public-evse',
  templateUrl: './public-evse.component.html',
  styleUrls: ['./public-evse.component.scss']
})
export class PublicEvseComponent implements OnInit {

  constructor(
    public clientService: ClientService,
    public activeRoute: ActivatedRoute) { }
  clientLogo: ClientLogo = new ClientLogo();
  qrCodeId;
  ngOnInit(): void {
    if (!isNaN(parseInt(this.activeRoute.snapshot.paramMap.get('qrCodeId'), 10))) {
      this.qrCodeId = parseInt(this.activeRoute.snapshot.paramMap.get('qrCodeId'), 10);
     // this.qrCodeId = this.qrCodeId;
    }
    this.getClientLogo();
  }

  getClientLogo(): void {
    if (Common.loginResponseModel.clientKey!=null) {
      this.clientService.getClientLogo(Common.loginResponseModel.clientKey).subscribe(
        (res) => {
          if (res.returnStatus) {
            this.clientLogo = res.data;
          }
        }
      );
    }
  }

}
