import { Component, OnInit } from '@angular/core';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ReserveNow } from '../../../models/charge-point-actions/reserve-now';
import { ValueDisplay } from '../../../models/value-display';

import { UserService } from '../../../services/user.service';
import { RFIDTagsService } from '../../../services/rfid-tags.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss']
})

export class ReserveComponent implements OnInit {

  reserveForm: UntypedFormGroup;
  reserveModel: ReserveNow;
  taglist;
  public users: ValueDisplay[] = [{ display: 'User1', value: 0 }, { display: 'User2', value: 2 }, { display: 'Kamal', value: 4 }];
  public data: ValueDisplay[] = [];
  public defaultuser: ValueDisplay = { display: 'Select User', value: 1 };

  constructor(private actionService: ChargingActionsService
    
    , private userService: UserService
    , private rfidTagsService: RFIDTagsService) {
    this.data = this.users.slice();
  }

  ngOnInit(): void {
    this.reserveForm = new UntypedFormGroup({
      userId: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
    });
    this.getUserList();
  }

  handleFilter(value) {
    this.data = this.users.filter((s) => s.display.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getUserList() {
    this.userService.getUsers().subscribe(response => {
      if (response.returnStatus) {
        this.taglist = response.data;
      }
    });
  }

  getRFIDTag() {
    this.rfidTagsService.getrfidtags().subscribe(
      (res) => {
        if (res.returnStatus) {
          this.taglist = res.data;
        } 
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      }
    );
  }

  onSubmit() {
    this.reserveModel = this.reserveForm.value;
    console.log(this.reserveModel);
    if (this.reserveForm.valid) {
      // this.actionService.reset(this.reserveModel).subscribe(
      //   (res) => {
      //     console.log(res);
      //   },
      //   (error) => {
      //     console.log(error);
      //   }

      // )
    }
  }

  onReset() {
    this.reserveForm.reset();
  }

}
