import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ValueDisplayString } from '../../../models/value-display-string';
import { ChangeAvailability} from '../../../models/charge-point-actions/change-availability';
import { ChargingActionsService } from '../../../services/charging-actions.service';
import { ToastService } from '../../../services/toast.service';
import { TriggerMessage } from '../../../models/charge-point-actions/trigger-message';
import Swal from 'sweetalert2';

import { changeAvailabilityValue } from '../../constant';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-change-availability',
  templateUrl: './change-availability.component.html',
  styleUrls: ['./change-availability.component.scss']
})
export class ChangeAvailabilityComponent implements OnInit {

  changeAvailabilityModel:ChangeAvailability
  changeAvailabilityForm: UntypedFormGroup;
  triggerMessageModel: TriggerMessage;
  public messageTypes: ValueDisplayString[] = [];
  public defaultmessageType: ValueDisplayString = { display: 'Type', value: null };
  submitted = false;

  constructor(
    private actionService: ChargingActionsService,
    private dialogRef: DynamicDialogRef,
    private toasterService: ToastService) { }

  ngOnInit(): void {
    this.messageTypes = changeAvailabilityValue;
    this.changeAvailabilityForm = new UntypedFormGroup({
      availType: new UntypedFormControl(null, Validators.required),
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.changeAvailabilityForm.valid) {
      //this.changeAvailabilityModel = this.changeAvailabilityForm.value;
      this.changeAvailabilityModel = new ChangeAvailability();
      this.changeAvailabilityModel.availType= this.changeAvailabilityForm.controls.availType.value;
      this.changeAvailabilityModel.reason = this.changeAvailabilityForm.controls.reason.value;
      this.changeAvailabilityModel.chargePointEVSEId = this.actionService.chargePointEVSEIdforAction;

      this.changeAvailabilityModel.chargePointEVSEId = this.actionService.chargePointEVSEIdforAction;
      this.actionService.changeAvailability(this.changeAvailabilityModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          else {
            this.submitted = false;
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: res.message,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      return;
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Error',
      //   text: 'Please fill required details',
      //   showConfirmButton: true,
      //   confirmButtonText: 'OK'
      // });
    }
  }

  onReset(): void {
    this.changeAvailabilityForm.reset();
    this.dialogRef.close();
  }

}
