<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="unlockForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Reason for unlocking<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>