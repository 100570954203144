import { Component, OnInit } from '@angular/core';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import Swal from 'sweetalert2';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Reason } from 'src/app/models/charge-point-actions/reason-for-manual-start';

@Component({
  selector: 'app-unlock',
  templateUrl: './unlock.component.html',
  styleUrls: ['./unlock.component.scss']
})
export class UnlockComponent implements OnInit {

  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  unlockModel: Reason;

  unlockForm: UntypedFormGroup;
  ngOnInit(): void {
    this.unlockForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit(): void {
    if (this.unlockForm.valid) {
      this.unlockModel = this.unlockForm.value;
      // this.actionService.reset(any).subscribe(
      //   (res) => {
      //     if (res.returnStatus) {
      //       Swal.fire({
      //         position: 'top-end',
      //         icon: 'success',
      //         title: 'Success',
      //         text: res.message,
      //         showConfirmButton: false,
      //         timer: 4000
      //       });
      //     }
      //     this.dialogRef.close();
      //   },
      //   (error) => {
      //     console.log(error);
      //     this.dialogRef.close();
      //   }
      // );
    }
    else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error',
        text: 'Please fill required details',
        showConfirmButton: true,
        confirmButtonText: 'OK'
      });
    }
  }

  onReset(): void {
    this.unlockForm.reset();
    this.dialogRef.close();
  }

}
