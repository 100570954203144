import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { StopSession } from '../../../models/charge-point-actions/stop-session';
import { ChargingActionsService } from '../../../services/charging-actions.service';

import Swal from 'sweetalert2';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-stop-session',
  templateUrl: './stop-session.component.html',
  styleUrls: ['./stop-session.component.scss']
})
export class StopSessionComponent implements OnInit {

  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  submitted = false;
  stopSessionForm: UntypedFormGroup;
  stopSessionModel: StopSession = new StopSession();

  ngOnInit(): void {
    this.stopSessionForm = new UntypedFormGroup({
      forceStop: new UntypedFormControl(false),
      reason: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.stopSessionForm.valid) {
      this.stopSessionModel = this.stopSessionForm.value;
      this.actionService.stopSession(this.stopSessionModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: res.message,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      return;
    }
  }

  onReset(): void {
    this.stopSessionForm.reset();
    this.dialogRef.close();
  }

}
