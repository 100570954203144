import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { Common } from '../common/common';
import { ApiUrls } from '../common/api-urls';
import { HttpClient } from '@angular/common/http';
import { TariffResponseVM } from '../models/tariff/tariff-response';
import { TariffRequestVM } from '../models/tariff/tariff-request';
import { ResponseModel } from '../models/response-model';
import { PaginationRequestModel } from '../models/general/pagination-request-model';
import { TariffTypeVM } from '../models/tariff/tariff-type-vm';
import { EvseDetailByTariffid } from '../models/tariff/evse-detail-by-tariffid';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

constructor(private http: HttpClient) { }

getTariffType(): Observable<ResponseModelList<TariffTypeVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.get<ResponseModelList<TariffTypeVM>>(ApiUrls.tariffTypes, options);
}

getTariffList(): Observable<ResponseModelList<TariffResponseVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.get<ResponseModelList<TariffResponseVM>>(ApiUrls.tariff, options);
}

getTariffListPagination(gridState : PaginationRequestModel): Observable<ResponseModelListForPaging<TariffResponseVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.post<ResponseModelListForPaging<TariffResponseVM>>(ApiUrls.tariffPagination, gridState, options);
}

getTariffById(tariffId: number): Observable<ResponseModel<TariffResponseVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.get<ResponseModel<TariffResponseVM>>(ApiUrls.tariff + '/' + tariffId, options);
}

addTariff(data: TariffRequestVM): Observable<ResponseModel<TariffRequestVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.post<ResponseModel<TariffRequestVM>>(ApiUrls.tariff, data, options);
}

updateTariff(tariffId: number, data: TariffRequestVM): Observable<ResponseModel<TariffRequestVM>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.put<ResponseModel<TariffRequestVM>>(ApiUrls.tariff + '/' + tariffId, data, options);
}

getEVSEListByTariffId(tariffId,customeState): Observable<ResponseModelListForPaging<EvseDetailByTariffid>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.post<ResponseModelListForPaging<EvseDetailByTariffid>>(ApiUrls.evseListByTariffId.replace('{tariffId}', tariffId),customeState, options);
}

// getTariffHistoryByTariffId(tariffId): Observable<ResponseModelList<any>> {
//   const options = { headers: Common.getAuthorizeHeader() };
//   return this.http.get<ResponseModelList<any>>(ApiUrls.tariffHistoryByTariffId.replace('{tariffId}', tariffId), options);
// }

deleteTariff(tariffId: number): Observable<ResponseModel<string>> {
  const options = { headers: Common.getAuthorizeHeader() };
  return this.http.delete<ResponseModel<string>>(ApiUrls.tariff + '/' + tariffId, options);
}
}
