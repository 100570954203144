import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChargePointResponseModel } from 'src/app/models/charge-point/charge-point-response';
import { EVSEResponseModel } from 'src/app/models/evse/evse-response';
import { ChargePonitService } from 'src/app/services/charge-point.service';
import { ChargingProfileService } from 'src/app/services/charging-profile.service';
import { ToastService } from 'src/app/services/toast.service';
import Swal from 'sweetalert2';
import {  chargingProfileSetOption } from '../../constant';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { SetChargingProfileModel } from 'src/app/models/charging-profile/set-charging-profile-model';
import { ValueDisplayString } from 'src/app/models/value-display-string';

@Component({
  selector: 'app-set-charging-profile',
  templateUrl: './set-charging-profile.component.html',
  styleUrls: ['./set-charging-profile.component.scss']
})
export class SetChargingProfileComponent implements OnInit, AfterViewInit {

  constructor(
    public chargingProfileService: ChargingProfileService,
    private dialogRef: DynamicDialogRef,
    private chargPointService: ChargePonitService,
    private toasterService: ToastService,
    public commonService: CommonService,
  ) { }
  source;
  isConnectorLevel: boolean;
  setChargingProfileForm: FormGroup;
  chargePointId: number;
  chargingProfileId: number;
  isChargePointSelected: boolean;
  submitted = false;
  setChargingProfile: SetChargingProfileModel = new SetChargingProfileModel();
  chargePoint: ChargePointResponseModel    = new ChargePointResponseModel();
  chargePointEVSEList: EVSEResponseModel[] = [new EVSEResponseModel()];
  public chargingProfileConnectorList = [{value: 0,display: '0'},{value: 1,display: '1'},{value: 2,display: '2'},{value: 3,display: '3'}];
  public chargePointList;
  @ViewChild('chargePointDP', { read: ElementRef , static: false}) chargePointDp: ElementRef;
  ngAfterViewInit(): void {
    this.source = fromEvent(this.chargePointDp.nativeElement, 'keyup');
    this.source.pipe(debounceTime(1200)).subscribe(c => {
      this.getChargePointList('charge_point',c.target.value);
    });
  }
  getChargePointList(filterKey, filterValue): void {
    this.commonService.getDataByFilter(filterKey, filterValue).subscribe(response => {
      if (response.returnStatus) {
        this.chargePointList = response.data;

      }
    },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.errors[0].message[0],
          showConfirmButton: true
        });
      });
  }
  ngOnInit(): void {
    this.chargePointId = this.chargingProfileService.chargePointIdforAction;
    this.chargingProfileId = this.chargingProfileService.chargingProfileIdforAction;
    this.isChargePointSelected = this.chargingProfileService.isChargePointSelected;
    if (this.chargingProfileService.chargingProfileSetOption === chargingProfileSetOption.profileSetOnConnector) {
      this.isConnectorLevel = true;
    }

    this.setChargingProfileForm = new FormGroup({
          chargePointId: new FormControl(null,Validators.required),
          connectorId: new FormControl(0),
        });
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.setChargingProfileForm.valid) {
      this.setChargingProfile.chargePointId = this.setChargingProfileForm.controls.chargePointId.value;
      if (this.isConnectorLevel) {
        this.setChargingProfile.connectorId = this.setChargingProfileForm.controls.connectorId.value;
      } else {
        this.setChargingProfile.connectorId = 0;
      }


      if (this.chargingProfileService.isSetChargingProfile) {
        this.chargingProfileService.setChargingProfile(this.chargingProfileId, this.setChargingProfile).subscribe(res => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
            this.dialogRef.close();
          }
          else {
            let errMessage = res.message;
            if (res.errors !== null) {
              errMessage = res.errors[0].message;
            }
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: errMessage,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
          (error) => {
            console.log(error);
            this.dialogRef.close();
          });
      }
      else {
        this.chargingProfileService.clearChargingProfile(this.chargingProfileId, this.setChargingProfile).subscribe(res => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
            this.dialogRef.close();
          }
          else {
            let errMessage = res.message;
            if (res.errors !== null) {
              errMessage = res.errors[0].message;
            }
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error',
              text: errMessage,
              showConfirmButton: true,
              confirmButtonText: 'OK'
            });
          }
          this.dialogRef.close();
          this.submitted = false;
        },
          (error) => {
            console.log(error);
            this.dialogRef.close();
          });
      }


    }
    else {
      return;
    }
  }
  onReset(): void {
    this.setChargingProfileForm.reset();
    this.dialogRef.close();
  }
  getChargePoints(): void {
    this.chargPointService.getChargePoints().subscribe(
      (res) => {
        if (res.returnStatus) {
          res.data.forEach((element) => {
            const cp = new ValueDisplayString();
            cp.value = String(element.chargePointId);
            cp.display = element.name;
          //  this.chargePointList.push(cp);
          });
        }
        else {
          this.toasterService.showError('Problem in Fetching New Charge Points.');
        }
      }
    );
  }

}
