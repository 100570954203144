<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="triggerMessageForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Message type<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <!-- <kendo-dropdownlist #typeList [data]="messageTypes" [defaultItem]="defaultmessageType" [textField]="'display'" [valueField]="'value'" formControlName="triggerMessage">
                            </kendo-dropdownlist> -->

                            <p-dropdown class="mr-2" #typeList formControlName="triggerMessage" appendTo="body"
                            [options]="messageTypes" optionValue="value" optionLabel="display">
                            </p-dropdown>

                            <br>
                            <span class="text-danger pt-2 float-left" *ngIf="(triggerMessageForm.controls.triggerMessage.touched || submitted) && triggerMessageForm.controls.triggerMessage.errors?.required">
                              Message Type is required.
                          </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reason<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                            <span class="text-danger pt-2 float-left" *ngIf="(triggerMessageForm.controls.reason.touched || submitted) && triggerMessageForm.controls.reason.errors?.required">
                              Reason is required.
                          </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>
