export class ReservationRequest{
  userId: number;
  reason: string;
  chargePointEvseId: number;  
  rfidTagId: number;
  reservationTime: ReservationRequestTime[]=[];
}

export class ReservationRequestTime {
  startAt: number;
  endAt: number;
  }