import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';
import { Common } from '../../common';
import Swal from 'sweetalert2';
import { Diagnostic } from 'src/app/models/charge-point-actions/diagnostic';


@Component({
  selector: 'app-get-diagnostic',
  templateUrl: './get-diagnostic.component.html',
  styleUrls: ['./get-diagnostic.component.scss']
})
export class GetDiagnosticComponent implements OnInit {

  public currentDate: Date = new Date(new Date().getDate());
  constructor(private actionService: ChargingActionsService,private dialogRef:DynamicDialogRef) { }
  submitted = false;
  getDiagnosticModel: Diagnostic;
  getDiagnosticForm: UntypedFormGroup;
  maxDate: Date | undefined;
  get startTime(): AbstractControl { return this.getDiagnosticForm.get('startTime'); }
  get stopTime(): AbstractControl { return this.getDiagnosticForm.get('stopTime'); }
  get retries(): AbstractControl { return this.getDiagnosticForm.get('retries'); }
  get retryInterval(): AbstractControl { return this.getDiagnosticForm.get('retryInterval'); }
  get reason(): AbstractControl { return this.getDiagnosticForm.get('reason'); }
  ngOnInit(): void {
    this.getDiagnosticForm = new UntypedFormGroup({
      retries: new UntypedFormControl(null,Validators.required),
      retryInterval: new UntypedFormControl(null,Validators.required),
      startTime: new UntypedFormControl(null,Validators.required),
      stopTime: new UntypedFormControl(null,Validators.required),
      reason: new UntypedFormControl('',Validators.required),
    });
    this.maxDate = new Date();
  }

  onSubmit() {
    this.getDiagnosticModel = this.getDiagnosticForm.value;
    this.submitted = true;
    this.getDiagnosticModel.startTime =  Common.getEPOCH(new Date(this.getDiagnosticModel.startTime))
    this.getDiagnosticModel.stopTime =  Common.getEPOCH(new Date(this.getDiagnosticModel.stopTime))
      this.actionService.diagnostics(this.getDiagnosticModel).subscribe(res => {
      if (res.returnStatus) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Success',
          text: res.message,
          showConfirmButton: false,
          timer: 4000
        });
        this.dialogRef.close();
      }
      else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error',
          text: res.message,
          showConfirmButton: true,
          confirmButtonText: 'OK'
        });
      }
      this.dialogRef.close();
      this.submitted = false;
      },
      (error) => {
        console.log(error);
        this.dialogRef.close();
      });
  }

  onReset() {
    this.getDiagnosticForm.reset();
  }

}
