import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';
import { ChipModule } from 'primeng/chip';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { AvatarModule } from 'primeng/avatar';
import {ToolbarModule} from 'primeng/toolbar';
import { KnobModule } from 'primeng/knob';

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    TableModule,
    ButtonModule,
    FileUploadModule,
    EditorModule,
    MultiSelectModule,
    DynamicDialogModule,
    OverlayPanelModule,
    DialogModule,
    DropdownModule,
    AccordionModule,
    TagModule,
    ImageModule,
    SkeletonModule,
    ChipModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
    CalendarModule,
    ColorPickerModule,
    OrganizationChartModule,
    RadioButtonModule,
    InputSwitchModule,
    SelectButtonModule,
    CheckboxModule,
    TabViewModule,
    AvatarModule,
    ToolbarModule,
    KnobModule
  ],
  providers: [DialogService]
})
export class PrimengModule { }
