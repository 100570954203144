import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ChargingActionsService } from 'src/app/services/charging-actions.service';

import Swal from 'sweetalert2';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Reason } from 'src/app/models/charge-point-actions/reason-for-manual-start';

@Component({
  selector: 'app-composite-schedule',
  templateUrl: './composite-schedule.component.html',
  styleUrls: ['./composite-schedule.component.scss']
})
export class CompositeScheduleComponent implements OnInit {

  constructor(private actionService: ChargingActionsService, private dialogRef: DynamicDialogRef) { }

  compScheduleModel: Reason;

  compScheduleForm: UntypedFormGroup;
  ngOnInit(): void {
    this.compScheduleForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit(): void {
    if (this.compScheduleForm.valid) {
      this.compScheduleModel = this.compScheduleForm.value;
      this.actionService.compositeSchedule(this.compScheduleModel).subscribe(
        (res) => {
          if (res.returnStatus) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: res.message,
              showConfirmButton: false,
              timer: 4000
            });
          }
          this.dialogRef.close();
        },
        (error) => {
          console.log(error);
          this.dialogRef.close();
        }
      );
    }
    else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error',
        text: 'Please fill required details',
        showConfirmButton: true,
        confirmButtonText: 'OK'
      });
    }
  }

  onReset(): void {
    this.compScheduleForm.reset();
    this.dialogRef.close();
  }


}
