import { LocationImageResponse } from '../location-image/location-image-response';

export class LocationRequestModel {
    public name: string;
    public shortDescription: string;
    public detailedDescription: string;
    public locationImageId: number;
    public latitude: number;
    public longitude: number;
    public threeWordsAddress: string;
    public address: string;
    public postcode: string;
    public region: string;
    public city: string;
    public country: string;
}

export class LocationUpdateModel {
    chargePointLocationId: number;
    name: string;
    shortDescription: string;
    detailedDescription: string;
    locationImageId: number;
    latitude: number;
    longitude: number;
    threeWordsAddress: string;
    address: string;
    postcode: string;
    region: string;
    city: string;
    country: string;
    imageFiles;
    imageName : string;
    imagePath : string;
}

export class LocationResponseModel {
    public chargePointLocationId: number;
    public name: string;
    public shortDescription: string;
    public detailedDescription: string;
    public latitude: number;
    public longitude: number;
    public threeWordsAddress: string;
    public address: string;
    public postcode: string;
    public region: string;
    public city: string;
    public country: string;
    public stateCode:string;
    public locationImage : LocationImageResponse[];
}

export class BindLocation {
    public chargePointLocationId: number;
    public name: string;
}
