export class AddUserRequest {
    public name: string;
    public email: string;
    public password: string;
    public confirmPassword: string;
    public roleId: string;
    public clientId: string;
    public isDisabled: boolean;

    //unmapped
    public userId: string;
}
