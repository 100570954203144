<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="dataTransferForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Vendor Id<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="vendorId" class="form-control" maxlength="255" />
                            <span class="text-danger pt-2 float-left" *ngIf="(dataTransferForm.controls.vendorId.touched || submitted) && dataTransferForm.controls.vendorId.errors?.required">
                                Vendor Id is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Message Id
                        </td>
                        <td>
                            <input type="text" formControlName="messageId" class="form-control" maxlength="50" />
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            Data
                        </td>
                        <td>
                            <textarea type="text" formControlName="data" class="form-control" rows="5"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reason<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" maxlength="255" />
                            <span class="text-danger pt-2 float-left" *ngIf="(dataTransferForm.controls.reason.touched || submitted) && dataTransferForm.controls.reason.errors?.required">
                                Reason is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>
