import { Component, NgZone, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Common } from './common/common';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LocalStorageKeys, Browser } from './common/constant';
import { LoaderService } from './services/loader.service';
import { MapHelper } from './helpers/map-helper';
import { Title } from '@angular/platform-browser';
import { ClientDomain } from './models/client-domain';

declare let clients;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'EV-HES-Portal';
    private subscription: Subscription;
    public message: string;
    asyncLoadCount = 0;
    clients: ClientDomain[] = clients;
    constructor(
        public router: Router,
        authService: AuthService,
        private ngZone: NgZone,
        private titleService: Title,
        public loaderService: LoaderService) {
        MapHelper.createMap();
        const loginResponse = localStorage.getItem(LocalStorageKeys.loginResponse);
        if (loginResponse && !Common.loginResponseModel) {
            Common.loginResponseModel = JSON.parse(loginResponse);
        }
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationStart) {
                if (ev.url !== '/auth/login' && ev.url !== '/auth/forgot-password' && ev.url !== '/auth/reset-password') {
                    if (!Common.loginResponseModel) {
                        authService.logout();
                    }
                }
            }
        });
    }


    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler() {
        window.localStorage.removeItem('tempdata');
    }

    ngOnInit(): void {
        this.getBrowserName();
        window['appCompRef'] = {
            zone: this.ngZone,
            component: this
        };
        this.setTitle();
    }

    setTitle(): void {
        this.clients.forEach(element => {
            if (Common.loginResponseModel) {
            if (Common.loginResponseModel.countryName.toUpperCase() === element.country.toUpperCase()) {
                this.titleService.setTitle(element.title);
                  }
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getBrowserName(): void {
        const nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset;
        let verOffset;
        let ix;
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        // In Opera, the true version is after "Opera" or after "Version"
        if (isIEOrEdge) {
            browserName = Browser.InternetExplorer;
            fullVersion = nAgt.substring(verOffset + 5);
        } else if ((verOffset = nAgt.indexOf(Browser.Opera)) !== -1) {
            browserName = Browser.Opera;
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf(Browser.Version)) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        } else if ((verOffset = nAgt.indexOf(Browser.Chrome)) !== -1) {
            browserName = Browser.Chrome;
            fullVersion = nAgt.substring(verOffset + 7);
            // if (parseFloat(fullVersion) < BrowserVersion.Chrome) {

            // }
        } else if ((verOffset = nAgt.indexOf(Browser.Safari)) !== -1) {
            browserName = Browser.Safari;
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf(Browser.Version)) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        } else if ((verOffset = nAgt.indexOf(Browser.Firefox)) !== -1) {
            browserName = Browser.Firefox;
            fullVersion = nAgt.substring(verOffset + 7);
            // if (parseFloat(fullVersion) < BrowserVersion.Firefox) {

            // }
            fullVersion = nAgt.substring(verOffset + 8);
        } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        if ((ix = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
    }
}

