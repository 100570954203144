import { Injectable } from '@angular/core';
import { Common } from '../common/common';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/response-model';
import { Observable } from 'rxjs';
import { ChargingProfileModel, ChargingProfileResponseModel } from '../models/charging-profile/charging-profile-model';
import {  ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { SetChargingProfileModel } from '../models/charging-profile/set-charging-profile-model';
import { ChargingSessionsVM } from '../models/activity/charging-sessions-vm';
import { PaginationRequestModel } from '../models/general/pagination-request-model';
import { ChargingProfileInputList } from '../models/charging-profile/charging-profile-input-list';
import { ChargingProfileList } from '../models/charging-profile/charging-profile-list';

@Injectable({
  providedIn: 'root'
})
export class ChargingProfileService {

  constructor(private http: HttpClient) { }
  chargePointIdforAction: number;
  chargingProfileIdforAction: number;
  chargingProfileSetOption: string;
  isChargePointSelected:boolean;
  isSetChargingProfile:boolean;
  chargingSession: ChargingSessionsVM;

  createChargingProfile(chargingProfile: ChargingProfileModel): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<void>>(ApiUrls.createChargingProfile, chargingProfile, options);
  }
  getChargingProfileInputList(): Observable<ResponseModel<ChargingProfileInputList>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChargingProfileInputList>>(ApiUrls.chargingProfileInputsByChargePointId, options);
  }
  getChargingProfileList(customeState:PaginationRequestModel): Observable<ResponseModelListForPaging<ChargingProfileResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargingProfileResponseModel>>(ApiUrls.getChargingProfile, customeState,options);
  }

  deleteChargingProfile(chargingProfileId: number): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModel<void>>(ApiUrls.deleteChargingProfile.replace("{chargingProfileId}",String(chargingProfileId)), options);
  }
  getChargingProfileDetailById(chargingProfileId: number): Observable<ResponseModel<ChargingProfileResponseModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChargingProfileResponseModel>>(ApiUrls.getChargingProfileDetailById.replace("{chargingProfileId}",String(chargingProfileId)), options);
  }
  updateChargingProfile(chargingProfile: ChargingProfileModel, chargingProfileId: number): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<void>>(ApiUrls.updateChargingProfile.replace("{chargingProfileId}",String(chargingProfileId)), chargingProfile, options);
  }
  setChargingProfile(chargingProfileId: number,setProfileModel:SetChargingProfileModel): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<void>>(ApiUrls.setChargingProfile.replace("{chargingProfileId}",String(chargingProfileId)), setProfileModel, options);
  }
  clearChargingProfile(chargingProfileId: number,setProfileModel:SetChargingProfileModel): Observable<ResponseModel<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<void>>(ApiUrls.clearChargingProfile.replace("{chargingProfileId}",String(chargingProfileId)), setProfileModel, options);
  }

  getChargingPofileList(chargePointId) : Observable<ResponseModelList<ChargingProfileList>>{
    const option  = {headers : Common.getAuthorizeHeader()};
    return this.http.get<ResponseModelList<ChargingProfileList>>(ApiUrls.getChargingProfileListByChargePoint.replace("{chargePointId}",chargePointId),option);
  }
  getChargingPofileListEVSE(evseId) : Observable<ResponseModelList<ChargingProfileList>>{
    const option  = {headers : Common.getAuthorizeHeader()};
    return this.http.get<ResponseModelList<ChargingProfileList>>(ApiUrls.getChargingProfileListByConnector.replace("{chargePointEvseId}",evseId),option);
  }

}
