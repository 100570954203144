import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { Common } from '../common/common';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/response-model';
import { EVSERequestModel } from '../models/evse/evse-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValueDisplayString } from '../models/value-display-string';
import { QRCodeResponse } from '../models/qr-code-response';
import { EVSEResponseModel } from '../models/evse/evse-response';
import { CommunicationLog, ActionLog, StatusLog } from '../models/charge-point-logs-vm';
import { EVSEUpdateModel } from '../models/evse/evse-update';
import { ChargingSessionsVM } from '../models/activity/charging-sessions-vm';

import { EvseRequestResponseLog } from '../models/evse/evse-request-response-log';
import { GetConnectorId } from '../models/evse/get-connector-id';
import { ReservationData } from '../models/evse/reservation-data';


@Injectable({
  providedIn: 'root'
})
export class EvseService {

  userId: number;
  reservationId: number;
  headersJson = Object.assign({ "Accept": "application/json", "Content-Type": "application/json" })
  httpOptions = {
  headers: new HttpHeaders(this.headersJson)
  };

  constructor(private http: HttpClient) {
  }

  // getConnectorList(): Observable<ResponseModelList<any>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.get<ResponseModelList<any>>(ApiUrls.connectorList, options);
  // }

  getActionList(chargePointEvseId: number): Observable<ResponseModelList<ActionLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ActionLog>>(ApiUrls.logList + chargePointEvseId + '/log/action', options);
  }

  getHardwareLogList(chargePointEvseId: number): Observable<ResponseModelList<StatusLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<StatusLog>>(ApiUrls.logList + chargePointEvseId + '/log/hardware', options);
  }

  getCommunicationLogList(chargePointEvseId: number, gridState): Observable<ResponseModelListForPaging<CommunicationLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<CommunicationLog>>(ApiUrls.communicationlogListByEvseId.replace("{chargePointEvseId}",String(chargePointEvseId)), gridState, options);
  }

  getDetailsList(chargePointEvseId: string, chargebox : string, ocppClientName : string , gridState): Observable<ResponseModelListForPaging<CommunicationLog>> {
    const options = { headers: Common.getAuthorizeHeaderForNode()};


    return this.http.post<ResponseModelListForPaging<CommunicationLog>>(ApiUrls.communicationDetailsLogListByEvseId
    .replace("{connectorId}",String(chargePointEvseId))
    .replace("{chargeBox}",String(chargebox))
    .replace("{ocppClientName}",String(ocppClientName)), gridState, options);
  }

  getType(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.evseType, options);
  }

  getStatus(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.evseStatus, options);
  }

  addEvse(data: EVSERequestModel): Observable<ResponseModelList<EVSERequestModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelList<EVSERequestModel>>(ApiUrls.addEvse, data, options);
  }

  updateEvse(data: EVSEUpdateModel, chargePointEvseId: number): Observable<ResponseModelList<EVSERequestModel>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModelList<EVSERequestModel>>(ApiUrls.addEvse + '/' + chargePointEvseId, data, options);
  }

  getPhases(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.evsePhase, options);
  }

  getConnectedPhases(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.evseConnectedPhase, options);
  }

  getPhaseRotation(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.evsePhaseRotation, options);
  }

  getQRCode(): Observable<ResponseModel<QRCodeResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<QRCodeResponse>>(ApiUrls.evseQRCode, options);
  }

  getNewConnectorId(chargePointId: number): Observable<ResponseModel<GetConnectorId>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<GetConnectorId>>(ApiUrls.evseNewConnectorId.replace('{chargePointId}', chargePointId.toString()), options);
  }

  deleteEVSE(chargePointEvseId: number) {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModel<string>>(ApiUrls.addEvse + '/' + chargePointEvseId, options);
  }

  getChargePointEVSE(chargePointEvseId: number) {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<EVSEResponseModel>>(ApiUrls.addEvse + '/' + chargePointEvseId, options);
  }

  getActiveChargingSession(chargePointEvseId: number) {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ChargingSessionsVM>>(ApiUrls.evseActiveSession + chargePointEvseId + '/session/active', options);
  }
  getReservationData(chargePointEvseId:number): Observable<ResponseModelList<ReservationData>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ReservationData>>(ApiUrls.reservationByEVSCId.replace('chargePointEvseId', String(chargePointEvseId)), options);
  }
  getEVSEReqResLogs(chargePointEvseId: number, gridState): Observable<ResponseModelListForPaging<EvseRequestResponseLog>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<EvseRequestResponseLog>>(ApiUrls.reqResLogByEVSEId.replace('{chargePointEvseId}', String(chargePointEvseId)), gridState, options);
  }
  // public DownloadRequestResponseDataExcel(chargePointEvseId: number, gridStateData: any) {
  //   return this.getExportRequestResponselistFileEndpoint(chargePointEvseId, gridStateData).toPromise();
  // }

  // public getExportRequestResponselistFileEndpoint(chargePointEvseId, gridStateData): Observable<any> {
  //   const gridState = JSON.parse(JSON.stringify(gridStateData));
  //   return this.http.post(ApiUrls.downloadRequestResponceDataByEVSEIdExcelfile.replace('{chargePointEvseId}', String(chargePointEvseId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
  // public DownloadCommunicationLogDataExcel(chargePointEvseId: number, gridStateData) {
  //   return this.getExportCommunicationFileEndpoint(chargePointEvseId,gridStateData).toPromise();
  // }
  // public getExportCommunicationFileEndpoint(chargePointEvseId,gridStateData): Observable<any> {
  //   const gridState = JSON.parse(JSON.stringify(gridStateData));
  //   return this.http.post(ApiUrls.downloadCommunicationLogByEVSEIdExcelfile.replace('{chargePointEvseId}', String(chargePointEvseId)), gridState, {  headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response: any) => {
  //         return response;
  //     }));
  // }

  private subjectName = new BehaviorSubject<string|null>(null);
  sendTariffType(message: string) {
    this.subjectName.next(message);
  }
  getTariffType(): Observable<string> {
  return this.subjectName.asObservable();
  }
}
