import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common/common';
import { ApiUrls } from '../common/api-urls';
import { ResponseModel } from '../models/response-model';
import { Observable } from 'rxjs';
import { ResponseModelList, ResponseModelListForPaging } from '../models/response-model-list';
import { ChangePasswordVM } from '../models/change-password-vm';
import { ForgotPasswordVM } from '../models/forgot-password-vm';
import { ResetPasswordVM } from '../models/reset-password-vm';
import { VerifyEmailVM } from '../models/verify-email-vm';
import { ProfileVM } from '../models/profile-vm';
import { UrlParameter } from '../common/constant';
import { AddUpdateUserResponse } from '../models/user/add-update-user-response';
import { AddCrmUser } from '../models/user/add-crm-user';

import 'automapper-ts';
import { CrmUsersListVM } from '../models/user/crm-users-list';

import { ValueDisplay } from '../models/value-display';
import { RFIDTagUserWiseVM } from '../models/rfid-tag/rfid-tag-user-vm';
import { UserStatus } from '../models/user/user-status';
import { RazorpayTransacionVM } from '../models/razorpay/razorpay-transaction';
import { ProfileImageVM } from '../models/profile-image';
import { UserActionsVM } from '../models/user/user-actions';
import { UserSession } from '../models/user/user-session';
import { PayOutstandingRequest } from '../models/user/pay-outstanding-request';

import { Settlement } from '../models/settlement';
import { ChargingSessionsListVM } from '../models/activity/charging-sessions-list-vm';
import { map } from 'rxjs/operators';
import { CreatePasswordVM } from '../models/create-password-vm';
import { UserTag } from '../models/user/user-tag';
import { PhonepeTransactionVM } from '../models/phonepe/phonepe-transaction-vm';
import { PayOutStandingResponse } from '../models/user/pay-outstanding-response';
import { VehicleDetailList } from '../models/user/vehicle-detail-response';
import { UserWiseAuthorizationVM } from '../models/activity/user-wise-authorization';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  userId: number;
  walletAmount: number;
  outstandingAmount: number;
  constructor(private http: HttpClient) {
  }

  getrfidtagsPagination(customState): Observable<ResponseModelListForPaging<RFIDTagUserWiseVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<RFIDTagUserWiseVM>>(ApiUrls.rfidTagPagination, customState, options);
  }
  getUserTags(userId, customState): Observable<ResponseModelListForPaging<UserTag>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<UserTag>>(ApiUrls.userTag.replace('{userId}', userId), customState, options);
  }
  createUser(data: AddCrmUser): Observable<ResponseModel<AddCrmUser>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<AddCrmUser>>(ApiUrls.registerCustomer, data, options);
  }

  updateUser(userId, data: AddCrmUser): Observable<ResponseModel<AddCrmUser>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<AddCrmUser>>(ApiUrls.updateUser + '/' + userId, data, options);
  }

  getUsers(): Observable<ResponseModelList<CrmUsersListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<CrmUsersListVM>>(ApiUrls.userList, options);
  }

  getUsersPagination(gridState): Observable<ResponseModelListForPaging<CrmUsersListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<CrmUsersListVM>>(ApiUrls.userListPagination, gridState, options);
  }

  exportUsers(cgridState) {
    return this.getExportUserFileEndpoint(cgridState).toPromise();
  }
  public getExportUserFileEndpoint(cgridState){
    return this.http.post(ApiUrls.userListExport, cgridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }

  getAvailableUsersForStartSession(): Observable<ResponseModelList<CrmUsersListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<CrmUsersListVM>>(ApiUrls.availableUsersForStartSessionList, options);
  }

  getUsersById(userId): Observable<ResponseModel<CrmUsersListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<CrmUsersListVM>>(ApiUrls.userList + '/' + userId, options);
  }

  delete(userId: number): Observable<ResponseModel<AddUpdateUserResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.
      delete<ResponseModel<AddUpdateUserResponse>>(ApiUrls.deleteUser.replace(UrlParameter.userId, userId.toString()), options);
  }

  changePassword(changePasswordVM: ChangePasswordVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.changePassword, changePasswordVM, options);
  }

  forgotPassword(forgotPasswordVM: ForgotPasswordVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getHeader() };
    return this.http.post<ResponseModel<string>>(ApiUrls.forgotPassword, forgotPasswordVM, options);
  }

  resetPassword(resetPasswordVM: ResetPasswordVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.resetPassword, resetPasswordVM, options);
  }

  createPassword(createPasswordVM: CreatePasswordVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.createPassword, createPasswordVM, options);
  }

  verifyEmail(verifyEmailVM: VerifyEmailVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.verifyEmail, verifyEmailVM, options);
  }

  updateProfile(profile: ProfileVM): Observable<ResponseModel<string>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<string>>(ApiUrls.userProfile, profile, options);
  }

  getUserRFIDTag(userId:number): Observable<ResponseModelList<RFIDTagUserWiseVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<RFIDTagUserWiseVM>>(ApiUrls.userRFIDTagList.replace(UrlParameter.userId, String(userId)), options);
  }

  setStatus(userId, data: UserStatus): Observable<ResponseModel<CrmUsersListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.put<ResponseModel<CrmUsersListVM>>(ApiUrls.setUserStatus.replace(UrlParameter.userId, userId), data, options);
  }

  getStatus(): Observable<ResponseModelList<ValueDisplay>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplay>>(ApiUrls.getUserStatus, options);
  }

  getNewMobileUsers(range: string): Observable<ResponseModel<number>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<number>>(ApiUrls.newMobileUser + '/' + range, options);
  }

  getAuthorizationsListUserWise(userId, gridState): Observable<ResponseModelListForPaging<UserWiseAuthorizationVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<UserWiseAuthorizationVM>>(ApiUrls.authorizationByUser.replace("{userId}", userId), gridState, options);
  }

  // getTransactionByUsers(userId, gridState): Observable<ResponseModelListForPaging<any>> {
  //   const options = { headers: Common.getAuthorizeHeader() };
  //   return this.http.post<ResponseModelListForPaging<any>>(ApiUrls.transactionListByUserId.replace("{userId}", userId), gridState, options);
  // }

  getChargingSessionListByUser(gridState): Observable<ResponseModelListForPaging<ChargingSessionsListVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModelListForPaging<ChargingSessionsListVM>>(ApiUrls.sessionHistory, gridState, options);
  }

  getSettlementData(userId:number): Observable<ResponseModelList<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<void>>(ApiUrls.settlement.replace('userId', String(userId)), options);
  }
  getReservationData(userId:number): Observable<ResponseModelList<void>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<void>>(ApiUrls.reservationByUserId.replace('userId',String(userId)), options);
  }

  getChargingSessionAnalyticsByUser(userId): Observable<ResponseModel<UserSession>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<UserSession>>
      (ApiUrls.getChargingSessionAnalyticsUserWise + '/' + userId + '/session', options);
  }

  getRazorpayTransaction(userId: number): Observable<ResponseModel<RazorpayTransacionVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<RazorpayTransacionVM>>(ApiUrls.userList + '/' + userId + '/razorpay/transaction', options);
  }
  getPhonepeTransaction(userId: number): Observable<ResponseModel<PhonepeTransactionVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<PhonepeTransactionVM>>(ApiUrls.userList + '/' + userId + '/phonepe/transaction', options);
  }
  addProfileImage(data: FormData): Observable<ResponseModel<ProfileImageVM>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.put<ResponseModel<ProfileImageVM>>(ApiUrls.addProfileImage, data, options);
  }

  getUserActions(userId): Observable<ResponseModelList<UserActionsVM>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<UserActionsVM>>(ApiUrls.getChargingSessionUserWise + '/' + userId + '/action', options);
  }
  payOutstanding(payoutobj: PayOutstandingRequest): Observable<ResponseModel<PayOutStandingResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.post<ResponseModel<PayOutStandingResponse>>(ApiUrls.payOutstanding.
      replace("userId", this.userId.toString()), payoutobj, options);
  }

  getSettlementById(settlementId:number): Observable<ResponseModel<Settlement>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<Settlement>>(ApiUrls.settlementById.replace('settlementId', String(settlementId)), options);
  }

  public downloadSessionDataExcel(userId: number, gridStateData) {
    return this.getExportSessionFileEndpoint(userId, gridStateData).toPromise();
  }
  public getExportSessionFileEndpoint(userId, gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadSessionDataByUserIdExcelfile.replace('{userId}', String(userId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }

  public downloadAuthorizationByUserExcel(userId: number, gridStateData) {
    return this.getExportAuthorizationFileEndpoint(userId, gridStateData).toPromise();
  }
  public getExportAuthorizationFileEndpoint(userId, gridStateData) {
    const gridState = JSON.parse(JSON.stringify(gridStateData));
    return this.http.post(ApiUrls.downloadAuthorizationDataByUserIdExcelfile.replace('{userId}', String(userId)), gridState, { headers: Common.getAuthorizeHeader(), observe: 'response', responseType: 'blob' }).pipe(map((response) => {
      return response;
    }));
  }
  getVehicle(userId:number): Observable<ResponseModelList<VehicleDetailList>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<VehicleDetailList>>(ApiUrls.vehicleListByUserId.replace('{userId}', String(userId)) , options);
  }
}
