export class AddPartnerContractVM{
    title: string;
    startDate: number;
    endDate: number;
    autoRenewal: true;
    startSession: true;
    stopSession: true;
    startReservation: true;
    stopReservation: true;
    restartStation: true;
    unlockConnector: true;
    firmwareUpdate: true;
    acConnectorsPartnerShare: number;
    dcConnectorsPartnerShare: number;
    monthlyPlatformFeePerChargePoint: number;
    monthlyPlatformFeePerAcEvse: number;
    monthlyPlatformFeePerDcEvse: number;
    partnerId: number;
}