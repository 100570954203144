<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="reserveForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Type
                        </td>
                      
                        <td>
                            <p-dropdown  #typeList formControlName="userId" appendTo="body" placeholder="Select Option"
                            [options]="taglist" optionValue="value" optionLabel="display" (onChange)="handleFilter($event)">
                            </p-dropdown>
                            <!-- <kendo-dropdownlist #typeList [data]="taglist" [filterable]="true" (filterChange)="handleFilter($event)" [defaultItem]="defaultuser" [textField]="'display'" [valueField]="'value'" formControlName="userId">
                            </kendo-dropdownlist> -->
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reason
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>