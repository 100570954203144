<div class="row">
  <div class="col-xl-12">
    <form [formGroup]="setChargingProfileForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
      <table class="table mb-0 table-bordered table-borderless create-evse">
        <tbody class="border-none">
          <tr *ngIf="!isChargePointSelected">
            <td width="200">Charge Point<span class="text-danger text-sm ml-2"><sup>*</sup></span></td>
            <td>
              <p-multiSelect  lazy="true" #chargePointDP [options]="chargePointList" placeholder="Select Charge Point"
                optionLabel="display" optionValue="value" [maxSelectedLabels]="1"
                selectedItemsLabel="{0} Items Selected" formControlName="chargePointId">
              </p-multiSelect>
              <br />
              <span class="text-danger pt-2 float-left"
                *ngIf="(setChargingProfileForm.controls.chargePointId.touched || submitted) && setChargingProfileForm.controls.chargePointId.errors?.required">
                Charge Point is required
              </span>
            </td>
          </tr>
          <tr *ngIf="isConnectorLevel">
            <td width="200">Connector<span class="text-danger text-sm ml-2"><sup>*</sup></span></td>
            <td>
              <p-dropdown [options]="chargingProfileConnectorList"
              optionLabel="display" optionValue="value" formControlName="connectorId"
                [ngClass]="{'is-invalid': setChargingProfileForm.controls.connectorId.invalid && (setChargingProfileForm.controls.connectorId.touched || setChargingProfileForm.controls.connectorId.dirty || submitted ) }">
              </p-dropdown>
              <br />
              <span class="text-danger pt-2 float-left"
                *ngIf="(setChargingProfileForm.controls.connectorId.touched || submitted) && setChargingProfileForm.controls.connectorId.errors?.required">
                Connector is required
              </span>
            </td>
          </tr>
          <tr>
            <td>

            </td>
          </tr>
          <tr>
            <td colspan="2" class="mt-3 text-center">
              <div class="d-inline">
                <input *ngIf="chargingProfileService.isSetChargingProfile" type="submit" class="btn btn-hes mr-3" value="Set Charging Profile">
                <input *ngIf="!chargingProfileService.isSetChargingProfile" type="submit" class="btn btn-hes mr-3" value="Clear Charging Profile">
                <input type="reset" class="btn btn-danger" value="Cancel">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
