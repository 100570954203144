import 'automapper-ts';
import { AddUserRequest } from '../models/user/add-user-request';
import { UpdateUserRequest } from '../models/user/update-user-request';
import { ChargingZoneUpdate } from '../models/charging-zone/charging-zone-update';
import { BindLocation } from '../models/location/location-vm';
import { AddCrmUser } from '../models/user/add-crm-user';
import { PartnerListVM } from '../models/partners/partner-list';
import { AddPartnerVM } from '../models/partners/add-partner';
import { ChargePointNameModel } from '../models/charge-point/charge-point-name';
import { LocationNameModel } from '../models/location/location-name';
import { CrmUsersNameVM } from '../models/user/users-name';
import { AddPartnerContractVM } from '../models/partner-contract/add-partner-contract';
import { ClientNameResponse } from '../models/client/client-name-response';
import { TariffNameListVM } from '../models/tariff/tariff-name-list';
import { CurrencyTypeVM } from '../models/currency/currency-type';
import { RFIDTagNamesVM } from '../models/rfid-tag/rfid-tag-names';

export class MapHelper {
    static createMap(): void {
        automapper.createMap('AddUserRequest', AddUserRequest)
            .forMember('userId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('userId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'))
            .forMember('email', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('email'))
            .forMember('password', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('password'))
            .forMember('confirmPassword', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('confirmPassword'))
            .forMember('isDisabled', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('isDisabled'))
            .forMember('clientId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('client.clientId'))
            .forMember('createdBy', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('createdBy'))
            .forMember('roleId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('role.roleId'));

        automapper.createMap('UpdateUserRequest', UpdateUserRequest)
            .forMember('userId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('userId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'))
            .forMember('email', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('email'))
            .forMember('isDisabled', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('isDisabled'))
            .forMember('clientId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('clientId'))
            .forMember('createdBy', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('createdBy'))
            .forMember('roleId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('roleId'));
        
        automapper.createMap('ChargingZoneUpdate', ChargingZoneUpdate)
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'))
            .forMember('status', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('status'))
            .forMember('locationId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('locationId'))
            .forMember('title', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('title'))
            .forMember('description', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('description'));

        automapper.createMap('BindLocation', BindLocation)
            .forMember('chargePointLocationId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('chargePointLocationId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('AddCrmUser', AddCrmUser)
            .forMember('firstName', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('firstName'))
            .forMember('lastName', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('lastName'))
            .forMember('email', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('email'))
            .forMember('phone', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('phone'));

        automapper.createMap('AddPartnerVM', AddPartnerVM)
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'))
            .forMember('email', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('email'))
            .forMember('type', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('type'))
            .forMember('typeDisplay', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('typeDisplay'))
            .forMember('registrationNo', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('registrationNo'))
            .forMember('vatId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('vatId'))
            .forMember('country', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('country'))
            .forMember('city', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('city'))
            .forMember('postcode', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('postcode'))
            .forMember('address', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('address'))
            .forMember('contactPerson', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('contactPerson'))
            .forMember('phone', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('phone'))
            .forMember('activateCorporateBilling', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('activateCorporateBilling'))
            .forMember('corporateBillingLimit', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('corporateBillingLimit'))
            .forMember('discount', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('discount'))
            .forMember('monthlyPlatformFee', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('monthlyPlatformFee'));

        automapper.createMap('PartnerListVM', PartnerListVM)
            .forMember('partnerId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('partnerId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('ChargePointNameModel', ChargePointNameModel)
            .forMember('chargePointId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('chargePointId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('LocationNameModel', LocationNameModel)
            .forMember('chargePointLocationId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('chargePointLocationId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('CrmUsersNameVM', CrmUsersNameVM)
            .forMember('userId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('userId'))
            .forMember('firstName', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('firstName'));

        automapper.createMap('AddPartnerContractVM', AddPartnerContractVM)
            .forMember('title', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('title'))
            .forMember('startDate', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('startDate'))
            .forMember('endDate', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('endDate'))
            .forMember('autoRenewal', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('autoRenewal'))
            .forMember('startSession', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('startSession'))
            .forMember('stopSession', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('stopSession'))
            .forMember('startReservation', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('startReservation'))
            .forMember('stopReservation', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('stopReservation'))
            .forMember('restartStation', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('restartStation'))
            .forMember('unlockConnector', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('unlockConnector'))
            .forMember('contactPerson', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('contactPerson'))
            .forMember('restartStation', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('restartStation'))
            .forMember('firmwareUpdate', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('firmwareUpdate'))
            .forMember('acConnectorsPartnerShare', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('acConnectorsPartnerShare'))
            .forMember('dcConnectorsPartnerShare', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('dcConnectorsPartnerShare'))
            .forMember('monthlyPlatformFeePerChargePoint', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('monthlyPlatformFeePerChargePoint'))
            .forMember('monthlyPlatformFeePerAcEvse', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('monthlyPlatformFeePerAcEvse'))
            .forMember('monthlyPlatformFeePerDcEvse', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('monthlyPlatformFeePerDcEvse'))
            .forMember('partnerId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('partnerId'));

        automapper.createMap('ClientNameResponse', ClientNameResponse)
            .forMember('clientId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('clientId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('RFIDTagNamesVM', RFIDTagNamesVM)
            .forMember('rfidTagId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('rfidTagId'))
            .forMember('tagId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('tagId'));

        automapper.createMap('TariffNameListVM', TariffNameListVM)
            .forMember('tariffId', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('tariffId'))
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'));

        automapper.createMap('CurrencyTypeVM', CurrencyTypeVM)
            .forMember('name', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('name'))
            .forMember('code', (opts: AutoMapperJs.IMemberConfigurationOptions) => opts.mapFrom('code'));
    }
}
