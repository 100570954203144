export class PartnerListVM{
    partnerId: number;
    name: string;
    display  : string;
    value : number;
    // email: string;
    // typeDisplay: string;
    // registrationNo: string;
    // contactPerson: string;
    // phone: string;
}
