import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message: string): void {
    this.toastr.success(message, 'Success', {
      closeButton: true,
      progressBar: true
    });
  }

  showError(message: string): void {
    this.toastr.error(message, 'Error', {
      closeButton: true,
      progressBar: true
    });
  }

  showWarning(message: string): void {
    this.toastr.warning(message, 'Warning', {
      closeButton: true,
      progressBar: true
    });
  }

  showInfo(message: string): void {
    this.toastr.info(message, 'Information', {
      closeButton: true,
      progressBar: true
    });
  }
}
