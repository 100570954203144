<div #kendoDialogContainer></div>
<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="reservationForm" (reset)="onReset()" (ngSubmit)="onSubmit()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Reservation For<span class="text-danger h5 ml-2"></span>
                        </td>
                        <td>
                         
                            <p-selectButton formControlName="availType" class="ml-auto"
                                [options]="reservationOptions" optionLabel="label" optionValue="value" (onChange)="onChange($event)">
                            </p-selectButton>
                        </td>

                    </tr>
                    <tr>
                     
                        <td *ngIf="reservationForm.controls.availType.value">
                            Select Mobile User<span *ngIf="reservationForm.controls.availType.value"
                                class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td *ngIf="!reservationForm.controls.availType.value">
                            Select RFID Tag<span *ngIf="!reservationForm.controls.availType.value"
                                class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                      
                            <p-dropdown lazy="true" #mobileUserRfId [options]="usersData" [placeholder]="placeholder"
                                optionLabel="display" optionValue="value" [filter]="true" formControlName="tagId">
                            </p-dropdown>
                            <br>
                            <span class="text-danger pt-2"
                                *ngIf="(reservationForm.controls.tagId.touched  || submitted) && reservationForm.controls.tagId.errors?.required">
                                <span *ngIf="!reservationForm.controls.availType.value"> Tag is required.</span>
                                <span *ngIf="reservationForm.controls.availType.value"> User is required.</span>
                            </span>
                        </td>
                    </tr>
                    <tr>

                        <label for="" class="pt-2 ml-2 text-lg">Reservation Date<span
                                class="text-danger h5 ml-2"><sup>*</sup></span></label>
                        <td>
                            <p-calendar formControlName="reservationDate" (onSelect)="onDateChange()" [maxDate]="max"
                                [minDate]="min" dateFormat="dd-MM-yy" [showIcon]="true"></p-calendar>
                        </td>
                    </tr>
                  

                    <tr>

                        <td>
                            Reason<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                            <span class="text-danger pt-2 float-left"
                                *ngIf="(reservationForm.controls.reason.touched  || submitted) && reservationForm.controls.reason.errors?.required">
                                Reason is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Select slots
                            <div style="height:200px;overflow:scroll;">
                                <div class="row">
                                    <div class="tooltip1 col-1 mr-3"
                                        *ngFor="let item of timeslots;let indexOfelement=index;">
                                        <!-- <br *ngIf="" [ngClass]="{'d-inline-block':indexOfelement%5!=0}"/> -->

                                        <span *ngIf="item.isOccupied" class="tooltiptext"><a class="grid-column-link"
                                                [href]='"/app/reservation/"+item.chargePointReservationId'>
                                                Reserved By: {{item.userInfo}}</a></span>
                                        <label class="btn" [for]="'ch'+indexOfelement"
                                            [ngClass]="{'btn-success':item.isClicked,'btn-secondary':!item.isAvailable ,'btn-danger':item.isOccupied && item.isAvailable,'btn-outline-primary':item.isAvailable && !item.isClicked && !item.isOccupied}">{{item.displaySlot}}</label>
                                        <input [id]="'ch'+indexOfelement" type="checkbox"
                                            [disabled]="!item.isAvailable || item.isOccupied"
                                            (change)="onCheckedChange(item.slot, $event.target.checked)"
                                            class="d-none"><br>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>