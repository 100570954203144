<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="startSessionForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            User<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <!-- <kendo-dropdownlist class="w-100" #userList [data]="data" [filterable]="true" (filterChange)="handleFilter($event)" [defaultItem]="defaultuser" [textField]="'email'" [valueField]="'userId'" formControlName="userId">
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span class="template" *ngIf="dataItem.chargingSessionId > 0">
                                        <a href="{{routes.chargingSessions}}/{{dataItem.chargingSessionId}}">
                                            {{dataItem.email}} - Charging
                                        </a>
                                    </span>
                                    <span class="template" *ngIf="dataItem.chargingSessionId === 0">
                                        {{dataItem.email}}
                                    </span>
                                    <span class="template" *ngIf="dataItem.chargingSessionId === null || dataItem.chargingSessionId === undefined">
                                        {{dataItem.email}}
                                    </span>
                                </ng-template>
                            </kendo-dropdownlist> -->

                            <!-- <p-dropdown (onFilter)="handleFilter($event)" appendTo="body" [filter]="true" placeholder="Select User" [options]="data" formControlName="userId" optionLabel="email" optionValue="userId">
                              <ng-template let-dataItem pTemplate="item">
                                    <span class="template" *ngIf="dataItem.chargingSessionId > 0">
                                      <a href="{{routes.chargingSessions}}/{{dataItem.chargingSessionId}}">
                                          {{dataItem.email}} - Charging
                                      </a>
                                  </span>
                                  <span class="template" *ngIf="dataItem.chargingSessionId === 0">
                                      {{dataItem.email}}
                                  </span>
                                  <span class="template" *ngIf="dataItem.chargingSessionId === null || dataItem.chargingSessionId === undefined">
                                      {{dataItem.email}}
                                  </span>
                                </ng-template>
                            </p-dropdown> -->

                            <p-dropdown placeholder="Select User" lazy="true" #mobileUserRfId [options]="usersData"
                            optionLabel="display" optionValue="value" [filter]="true"
                            formControlName="userId" >
                            <ng-template let-dataItem pTemplate="item">
                              <span class="template" *ngIf="dataItem.chargingSessionId > 0">
                                <a href="{{routes.chargingSessions}}/{{dataItem.chargingSessionId}}">
                                    {{dataItem.display}} - Charging
                                </a>
                            </span>
                            <span class="template" *ngIf="dataItem.chargingSessionId === 0">
                                {{dataItem.display}}
                            </span>
                            <span class="template" *ngIf="dataItem.chargingSessionId === null || dataItem.chargingSessionId === undefined">
                                {{dataItem.display}}
                            </span>
                          </ng-template>
                            </p-dropdown>


                            <br>
                            <span class="text-danger pt-2" *ngIf="(startSessionForm.controls.userId.touched || submitted) && startSessionForm.controls.userId.errors?.required">
                                User is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Charging Option <span class="text-danger h5 ml-2"><sup>*</sup></span> </td>
                        <td>
                          <p-selectButton formControlName="option" (onChange)="handleChargingOption($event)" [options]="chargingOptions" optionLabel="label" optionValue="value"></p-selectButton>
                          <span class="text-danger pt-2 float-left" *ngIf="(startSessionForm.controls.option.touched || submitted) && startSessionForm.controls.option.errors?.required">
                            Charging Option is required.
                        </span>
                        </td>
                    </tr>
                    <tr *ngIf="freeKwh">
                        <td>
                            kWh
                        </td>
                        <td>
                            <input type="number" value="0" formControlName="kwh" class="form-control" />
                            <span class="text-danger pt-2" *ngIf="(startSessionForm.controls.kwh.touched || submitted) && startSessionForm.controls.kwh.errors?.required">
                                kWh is required.
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="isAmount">
                        <td>
                            Amount
                        </td>
                        <td>
                            <input type="number" value="0" formControlName="amount" class="form-control" />
                            <span class="text-danger pt-2" *ngIf="(startSessionForm.controls.amount.touched || submitted) && startSessionForm.controls.amount.errors?.required">
                              Amount is required.
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="freeDuration">
                        <td>
                            Duration (HH:MM)
                        </td>
                        <td>
                            <!-- <input type="number" value="0" formControlName="minutes" class="form-control" /> -->
                            <p-calendar placeholder="HH:MM" [(ngModel)]="defaultDuaration" formControlName="minutes"  [timeOnly]="true" [showTime]="true" [showSeconds]="false" inputId="time"></p-calendar>
                            <br>
                            <span class="text-danger pt-2" *ngIf="(startSessionForm.controls.minutes.touched || submitted) && startSessionForm.controls.minutes.errors?.required">
                              Duration is required.
                            </span>
                          </td>
                    </tr>
                    <tr>
                        <td>
                            Reason<span class="text-danger h5 ml-2"><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                            <span class="text-danger pt-2 float-left" *ngIf="(startSessionForm.controls.reason.touched || submitted) && startSessionForm.controls.reason.errors?.required">
                                Reason is required.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>
