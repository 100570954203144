import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResponseModel } from '../models/response-model';
import { LoginResponseV2 } from '../models/auth/login-response';
import { ApiUrls } from '../common/api-urls';
import { HttpClient} from '@angular/common/http';
import { Common } from '../common/common';
import { ExchangeTokenRequest } from '../models/auth/exchange-token-request';
import { LoginRequest } from '../models/auth/login-request';
import { LocalStorageKeys } from '../common/constant';
import { ExchangeTokenResponse } from '../models/auth/exchange-token-response';
import { EMSPLoginResponse } from '../models/auth/emsp-login-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private onTokenChang = new Subject<boolean>();
  tokenChanged = this.onTokenChang.asObservable();
  public redirectUrl: string;
  constructor(private http: HttpClient) {
  }

  announceTokenChanged(): void {
    this.onTokenChang.next(true);
  }
  login(loginRequest: LoginRequest): Observable<ResponseModel<LoginResponseV2>> {
    const options = Common.getHeader();
    return this.http.post<ResponseModel<LoginResponseV2>>(ApiUrls.login, loginRequest, { headers: options });
  }
  exchangeToken(exchangeTokenRequest: ExchangeTokenRequest): Observable<ResponseModel<ExchangeTokenResponse>> {
    const options = Common.getHeader();
    return this.http.post<ResponseModel<ExchangeTokenResponse>>(ApiUrls.exchangeToken, exchangeTokenRequest, { headers: options });
  }
  logout(): void {
    localStorage.removeItem(LocalStorageKeys.loginResponse);
  }
  ocpLogin(ocpLoginRequest:LoginRequest):Observable<ResponseModel<EMSPLoginResponse>>{
    const options=Common.getHeader();
    return this.http.post<ResponseModel<EMSPLoginResponse>>(ApiUrls.ocpLogin,ocpLoginRequest,{headers:options});
  }
}
