import { Injectable } from '@angular/core';
import { ValueDisplayString } from '../models/value-display-string';
import { HttpClient } from '@angular/common/http';
import { ResponseModelList } from '../models/response-model-list';
import { ResponseModel } from '../models/response-model';
import { Common } from '../common/common';
import { ApiUrls } from '../common/api-urls';
import { Observable } from 'rxjs';
import { ClientResponse } from '../models/client/client-response';
import { AddClientRequest } from '../models/client/client-request';
import { ClientLogo } from '../models/client-logo';
import { CountryListModel } from '../models/country-list';
import { CountryTime } from '../models/client/country-time';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getClientTypes(): Observable<ResponseModelList<ValueDisplayString>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ValueDisplayString>>(ApiUrls.clientTypes, options);
  }

  getClients(): Observable<ResponseModelList<ClientResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModelList<ClientResponse>>(ApiUrls.client, options);
  }

  getClientsById(): Observable<ResponseModel<ClientResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ClientResponse>>(ApiUrls.client, options);
  }

  addClient(data: FormData): Observable<ResponseModel<AddClientRequest>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.post<ResponseModel<AddClientRequest>>(ApiUrls.client, data, options);
  }

  updateClient(data: FormData, clientId: number): Observable<ResponseModel<AddClientRequest>> {
    const options = { headers: Common.getAuthorizeMultiPartHeader() };
    return this.http.put<ResponseModel<AddClientRequest>>(ApiUrls.updateClient + '/' + clientId, data, options);
  }

  deleteClient(email: string): Observable<ResponseModelList<ClientResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.delete<ResponseModelList<ClientResponse>>(ApiUrls.deleteClientAdmin + '/' + email, options);
  }

  getClientLogo(clientKey: string): Observable<ResponseModel<ClientLogo>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ClientLogo>>(ApiUrls.client + '/' + clientKey + '/logo', options);
  }

  getCountryList(): Observable<[]> {
    const options = { headers: Common.getCountryStateCityAPIHeader() };
    return this.http.get<[]>(ApiUrls.country, options);
  }

getCountryListV2(): Observable<ResponseModel<CountryListModel>> {
    const options = { headers: Common.getCountryStateCityAPIHeader() };
    return this.http.get<ResponseModel<CountryListModel>>(ApiUrls.countryList, options);
  }

  getTimezones(): Observable<ResponseModel<CountryTime[]>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<CountryTime[]>>(ApiUrls.timeZones, options);
  }
  getOCPPClientName(): Observable<ResponseModel<ClientResponse>> {
    const options = { headers: Common.getAuthorizeHeader() };
    return this.http.get<ResponseModel<ClientResponse>>(ApiUrls.client + '/' + 'ocpp', options);
  }
}
